import React, { useState, useEffect, useCallback } from "react";
import firebase from "firebase/compat/app";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Snackbar from "@mui/material/Snackbar";
import { Alert as MuiAlert } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import { css } from "@emotion/react";

import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import CircularProgress from "@mui/material/CircularProgress";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { useBottomScrollListener } from "react-bottom-scroll-listener";

import "date-fns";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";

import { returnBackgroundColour } from "./Utils/returnBackgroundColour";

import "firebase/firestore";
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyBvy9wwbHmVfNXy_XiaengbrcC0AeSHWp0",
    authDomain: "its-connect-main.firebaseapp.com",
    projectId: "its-connect-main",
    storageBucket: "its-connect-main.appspot.com",
    messagingSenderId: "1001127722110",
    appId: "1:1001127722110:web:7db56f4369dbc7a8a96c2f",
    measurementId: "G-KB3ZEJVTPK",
  });
} else {
  firebase.app();
}

const Trello = require("trello");
const trello = new Trello(
  "8991105779d1e51b2d00fb7d0e53fdf4",
  "bd2df1f9229a34b63c8a5a5f59d75f3529f13475b8d0e27b5e3065e6252a987a"
);

const firestore = firebase.firestore();
const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
const arrayRemove = firebase.firestore.FieldValue.arrayRemove;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const jobTypes = [
  { title: "Creative Life (-LC)" },
  { title: "Creative Flatlay (-PC)" },
  { title: "Design (-D)" },
  { title: "Life (-L)" },
  { title: "Ghost (-P)" },
  { title: "Ghost/Flatlay (-P)" },
  { title: "Ghost/Product (-P)" },
  { title: "Ghost/Jewellery (-P)" },
  { title: "Flatlay (-P)" },
  { title: "Hanging Flatlay (-P)" },
  { title: "Jewellery (-P)" },
  { title: "Product (-P)" },
  { title: "Retouching (-R)" },
  { title: "Video (-V)" },
  { title: "Video Editing (-E)" },
];
const studios = [
  { name: "Studio 1" },
  { name: "Studio 2A" },
  { name: "Studio 2B" },
  { name: "Studio 2C" },
  { name: "Studio 3" },
  { name: "Studio 4" },
];
const dayTypes = [{ type: "Full Day" }, { type: "Half Day" }];
const faceNoFaceTypes = [{ type: "Face" }, { type: "No Face" }];
const bookedByTypes = [{ type: "Booked By ITS" }, { type: "Booked By Client" }];
const paidByTypes = [{ type: "Paid By ITS" }, { type: "Paid By Client" }];
const skeletonTableCell = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
];
const skeletonTableRow = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const Jobs = ({ user }) => {
  // const [loadLimit, setLoadLimit] = useState(30);
  const loadLimit = 1000;

  // console.log(new Date().getFullYear())
  const [lastDoc, setLastDoc] = useState(null);
  // console.log(lastDoc)
  const [JobData, setJobData] = useState(null);
  // eslint-disable-next-line
  const [visibleYear, setVisibleYear] = useState(new Date().getFullYear());
  const [allYears, setAllYears] = useState([]);
  // const [showDeleteJob, setShowDeleteJob] = useState(false);
  const showDeleteJob = false;
  // const [showLoadingMoreData, setShowLoadingMoreData] = useState(true);

  const [clientSearch, setClientSearch] = useState("");

  const [staffData, setStaffData] = useState([]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("StaffData")
      .orderBy("firstName", "asc")
      .onSnapshot((collection) => {
        const sd = collection.docs.map((staff) => {
          const staffDataRaw = staff.data();
          const data = {};
          data.id = staff.id;
          data.firstName = staffDataRaw.firstName;
          data.lastName = staffDataRaw.lastName;
          data.preferredName = staffDataRaw.preferredName;
          if (staffDataRaw.currentlyEmployed) {
            return data;
          } else {
            return false;
          }
        });
        let newSd = [];
        sd.forEach((s) => {
          if (s) newSd.push(s);
        });
        setStaffData([...newSd]);
      });
    // eslint-disable-next-line
  }, [firebase]);

  useEffect(() => {
    const main = async () => {
      const yearsRaw = await firestore.collection("JobData").doc("years").get();
      const yearsData = yearsRaw.data();
      const years = yearsData.years;
      years.sort(function (a, b) {
        return b - a;
      });
      setAllYears([...years]);
    };
    main();
  }, [setAllYears]);

  useEffect(() => {
    let reference = firestore
      .collection("JobData")
      .where("year", "==", 2023)
      .orderBy("jobNumber", "desc");

    if (clientSearch) {
      reference = reference.where("client", "==", clientSearch);
    } else {
      reference = reference.limit(loadLimit);
    }

    const unsub = reference.onSnapshot((collection) => {
      const initialData = collection.docs
        .filter((j) => {
          const jd = j.data();
          // if (jd.client === "Morrissey Media") {
          //   if (user.email === "alex@imagesthatsell.com.au") {
          //     return true;
          //   } else return false;
          // }
          console.log(jd.jobNumber);
          if (!jd.jobNumber) return false;
          return true;
        })
        .map((job) => {
          const data = job.data();
          data.id = job.id;
          // console.log(user.email)
          return data;
        });
      console.log(initialData);

      if (collection.docs[collection.docs.length - 1]) {
        setLastDoc(collection.docs[collection.docs.length - 1]);
      }
      setJobData(initialData);
    });
    return () => {
      unsub();
    };
  }, [user, visibleYear, clientSearch]);

  const loadMoreData = () => {
    if (!JobData) {
      return;
    }
    if (JobData.length === 0) {
      return;
    }
    if (JobData.length > 0) {
      // setShowLoadingMoreData(true)
      let reference = firestore
        .collection("JobData")
        .orderBy("jobNumber", "desc")
        .where("year", "==", visibleYear)
        .startAfter(lastDoc);

      if (clientSearch) {
        reference = reference.where("client", "==", clientSearch);
      } else {
        reference = reference.limit(loadLimit / 2);
      }

      reference.get().then((collection) => {
        const newData = collection.docs.map((job) => {
          const data = job.data();
          data.id = job.id;
          return data;
        });
        if (collection.docs[collection.docs.length - 1]) {
          setLastDoc(collection.docs[collection.docs.length - 1]);
        }
        setJobData([...JobData, ...newData]);
      });
    }
  };

  useBottomScrollListener(loadMoreData, {
    offset: 700,
    debounce: 500,
    triggerOnNoScroll: false,
  });

  // const clientDataQuery = firestore
  //   .collection("ClientDatabase")
  //   .orderBy("brand", "asc");
  // const [clientData] = useCollectionData(clientDataQuery, { idField: "id" });

  const [clientData, setClientData] = useState([]);

  useEffect(() => {
    const unsubscribe = firestore
      .collection("ClientDatabase")
      .orderBy("brand", "asc")
      .onSnapshot((snapshot) => {
        const clients = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClientData([...clients]);
      });

    return () => unsubscribe();
  }, []);

  const [openNumbersOnly, setOpenNumbersOnly] = useState(false);
  const closeNumbersOnly = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNumbersOnly(false);
  };

  const [openFillRequired, setOpenFillRequired] = useState(false);
  const closeFillRequired = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFillRequired(false);
  };

  const [newJobAlertText, setNewJobAlertText] = useState("");
  const [openNewJobAlert, setOpenNewJobAlert] = useState(false);

  const closeNewJobAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNewJobAlert(false);
    setNewJobAlertText("");
  };

  const [disableCloseModal, setDisableCloseModal] = useState(false);
  const [newJobModal, setNewJobModal] = useState(false);

  const getLatestJobNumber = useCallback(() => {
    if (JobData && JobData) {
      for (let i = 0; i < JobData.length; i++) {
        if (JobData[i].client === "Morrissey Media") {
          continue;
        } else {
          const year = new Date().getFullYear().toString().substr(-2);
          const newNum =
            parseInt(JobData[i].jobNumber.toString().substr(2)) + 1;
          const newFullNum = parseInt(`${year}${newNum}`);
          // return (parseInt(JobData[i].jobNumber) + 1).toString()
          return newFullNum;
        }
      }
    }
  }, [JobData]);

  const newJobModalClose = () => {
    if (disableCloseModal) {
      return;
    }

    setJobNumberNew(getLatestJobNumber());
    setClientNew(null);
    setMultipleDaysNew(null);
    setJobTypeNew(null);
    setShootDateNew(null);
    setDueDateNew(null);
    setExpressNew(null);
    setExpressDueDateNew(null);

    setJobNumberNewError(false);
    setClientNewError(false);
    setJobTypeNewError(false);
    setDueDateNewError(false);

    setCreateTrelloCard(true);

    setNewJobModal(false);
  };
  const [jobNumberNew, setJobNumberNew] = useState(null);
  const [jobNumberNewError, setJobNumberNewError] = useState(false);
  const [clientNew, setClientNew] = useState(null);
  const [clientNewError, setClientNewError] = useState(false);
  const [multipleDaysNew, setMultipleDaysNew] = useState(null);
  const [jobTypeNew, setJobTypeNew] = useState(null);
  const [jobTypeNewError, setJobTypeNewError] = useState(false);
  const [shootDateNew, setShootDateNew] = useState(null);
  const [dueDateNew, setDueDateNew] = useState(null);
  const [dueDateNewError, setDueDateNewError] = useState(false);
  const [expressNew, setExpressNew] = useState(false);
  const [expressDueDateNew, setExpressDueDateNew] = useState(null);

  const [createTrelloCard, setCreateTrelloCard] = useState(true);

  useEffect(() => {
    if (JobData && JobData) {
      setJobNumberNew(getLatestJobNumber());
    }
  }, [JobData, getLatestJobNumber]);

  const updateStateDebounce = debounce(async (value, func) => {
    func(value);
  }, 500);

  const newJobModalBody = (
    <div
      style={{
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        position: "absolute",
        width: "300px",
        backgroundColor: "#ffffff",
        boxShadow: 0,
        padding: "20px",
        borderRadius: "5px",
        outline: "none",
      }}
    >
      <div
        style={{
          display: disableCloseModal ? "flex" : "none",
          zIndex: 1000,
          background: "white",
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <p>
          Creating job &amp; trello card.
          <br />
          <br />
          Dont close this page, or data may not be added properly
          <br />
          <br />
          This box will close automatically when done
        </p>
        <div>
          <CircularProgress disableShrink />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h3>New Job</h3>
        <TextField
          error={jobNumberNewError}
          id={`jobNumberNEW`}
          label="Job Number *"
          variant="outlined"
          defaultValue={jobNumberNew}
          onChange={(e) => {
            updateStateDebounce(parseInt(e.target.value), setJobNumberNew);
          }}
          style={{ width: "100%" }}
          className="newJobItem"
        />
        <Autocomplete
          id={`clientNEW`}
          options={clientData && clientData.map((c) => c.brand)}
          getOptionLabel={(opts) => opts}
          style={{ width: "100%" }}
          onInput={(e) => {
            updateStateDebounce(e.target.value, setClientNew);
          }}
          onChange={(e) => {
            updateStateDebounce(e.target.innerText, setClientNew);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Client *"
              variant="outlined"
              error={clientNewError}
            />
          )}
          className="newJobItem"
        />
        <TextField
          id={`multipleDaysNEW`}
          label="Multiple Days"
          variant="outlined"
          onChange={(e) => {
            updateStateDebounce(e.target.value, setMultipleDaysNew);
          }}
          style={{ width: "100%" }}
          className="newJobItem"
        />
        <Autocomplete
          id={`jobTypeFullNEW`}
          options={jobTypes.map((j) => j.title)}
          getOptionLabel={(opts) => opts}
          style={{ width: "100%" }}
          onInput={(e) => {
            updateStateDebounce(e.target.value, setJobTypeNew);
          }}
          onChange={(e) => {
            updateStateDebounce(e.target.innerText, setJobTypeNew);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Job Type *"
              variant="outlined"
              error={jobTypeNewError}
            />
          )}
        />
        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            autoOk={true}
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            style={{ width: "100%" }}
            id={`shootDateNew`}
            label="Shoot Date"
            inputVariant="outlined"
            value={shootDateNew || null}
            onChange={(d) => {
              setShootDateNew(d.toISOString());
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <KeyboardDatePicker
            error={dueDateNewError}
            disableToolbar
            autoOk={true}
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            style={{ width: "100%" }}
            id={`dueDateNew`}
            label="Due Date *"
            inputVariant="outlined"
            value={dueDateNew || null}
            onChange={(d) => {
              setDueDateNew(d.toISOString());
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider> */}
        <div className="center">
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                color="primary"
                onChange={() => setExpressNew(!expressNew)}
              />
            }
            label="Express"
            labelPlacement="end"
          />
        </div>
        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {expressNew ? (
            <KeyboardDatePicker
              disableToolbar
              autoOk={true}
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              style={{ width: "100%" }}
              id={`expressDueDateNew`}
              label="Express Due Date"
              inputVariant="outlined"
              value={expressDueDateNew}
              onChange={(d) => {
                setExpressDueDateNew(d.toISOString());
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          ) : (
            <div></div>
          )}
        </MuiPickersUtilsProvider> */}
        <div className="center">
          <FormControlLabel
            value="top"
            checked={createTrelloCard}
            control={
              <Checkbox
                color="primary"
                onChange={() => setCreateTrelloCard(!createTrelloCard)}
              />
            }
            label="Create Trello Card"
            labelPlacement="end"
          />
        </div>
        <div className="center" style={{ paddingTop: "10px" }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={async () => {
              setDisableCloseModal(true);

              let error;
              let jobName;

              if (!jobNumberNew) {
                error = true;
                setJobNumberNewError(true);
              } else {
                setJobNumberNewError(false);
              }
              if (!clientNew) {
                error = true;
                setClientNewError(true);
              } else {
                setClientNewError(false);
              }
              if (!jobTypeNew) {
                error = true;
                setJobTypeNewError(true);
              } else {
                setJobTypeNewError(false);
              }
              if (!dueDateNew) {
                error = true;
                setDueDateNewError(true);
              } else {
                setDueDateNewError(false);
              }
              if (error) {
                setOpenFillRequired(true);
                return;
              }

              const jobTypeShort = jobTypeNew.match(/^.*\((-.*)\)/)[1];

              if (multipleDaysNew) {
                jobName = `${jobNumberNew}-${clientNew}-${multipleDaysNew}${jobTypeShort}`;
              } else {
                jobName = `${jobNumberNew}-${clientNew}${jobTypeShort}`;
              }

              const obj = {
                jobName: jobName,
                jobNumber: parseInt(jobNumberNew),
                client: clientNew,
                multipleDays: multipleDaysNew,
                jobTypeFull: jobTypeNew,
                jobTypeShort: jobTypeShort,
                shootDate: shootDateNew,
                dueDate: dueDateNew,
                express: expressNew,
                expressDueDate: expressDueDateNew,
                backgroundRolls: "",
                bookedFinish: "",
                bookedStart: "",
                catering: [],
                creativeDayType: "",
                dayLength: "",
                dayType: "",
                finishTime: "",
                hmu: [],
                images: "",
                imagesProcessed: "",
                imagesProcessedNumbers: 0,
                imagesScheduledToBeShot: "",
                imagesSupplied: "",
                invoicingSpecialRequirements: "",
                lunch: "",
                models: [],
                operator: "",
                overtime: "",
                photographer: "",
                retoucher: "",
                retouchingSpecialRequirements: "",
                retouchingTime: "",
                shootSpecialRequirements: "",
                startTime: "",
                studio: "",
                stylist: "",
                outsourcers: [
                  {
                    outsourcer: "",
                    dueDate: null,
                    sentDate: null,
                    staff: "",
                    lineItems: [{ type: "", images: "", price: "" }],
                  },
                ],
                trelloCardID: "",
                operatorChecklist: {
                  item1: false,
                  item2: false,
                  item3: false,
                  item4: false,
                  item5: false,
                  item6: false,
                  item7: false,
                  item8: false,
                },
                year: new Date().getFullYear(),
              };
              let jobId = await firestore
                .collection("JobData")
                .add(obj)
                .then((doc) => {
                  return doc.id;
                });
              let newJobText = "Job";
              if (createTrelloCard) {
                const addCardResult = await createCard(jobName, dueDateNew);

                firestore
                  .collection("JobData")
                  .doc(jobId)
                  .update({ trelloCardID: addCardResult.id });

                if (expressNew) {
                  addLabelToCard(addCardResult.id, "5f0e8630fb83455e28da9908");
                }

                await addChecklistToCard(
                  addCardResult.id,
                  "Colour Corrections"
                );
                await addChecklistToCard(addCardResult.id, "Colour Changes");
                await addChecklistToCard(addCardResult.id, "Retouching Notes");

                const clientData = await firestore
                  .collection("ClientDatabase")
                  .where("brand", "==", clientNew)
                  .get()
                  .then((docs) => {
                    return docs.docs[0].data();
                  });

                if (clientData.generalChecklist.length > 0) {
                  const generalChecklistResult = await addChecklistToCard(
                    addCardResult.id,
                    "General Checklist"
                  ).then((res) => {
                    return res;
                  });
                  for (let i = 0; i < clientData.generalChecklist.length; i++) {
                    await trello.addItemToChecklist(
                      generalChecklistResult.id,
                      clientData.generalChecklist[i]
                    );
                  }
                }
                if (jobTypeNew?.includes("(-L)")) {
                  if (clientData.lifeChecklist.length > 0) {
                    const lifeChecklistResult = await addChecklistToCard(
                      addCardResult.id,
                      "Life Checklist"
                    ).then((res) => {
                      return res;
                    });
                    for (let i = 0; i < clientData.lifeChecklist.length; i++) {
                      await trello.addItemToChecklist(
                        lifeChecklistResult.id,
                        clientData.lifeChecklist[i]
                      );
                    }
                  }
                }
                if (jobTypeNew?.includes("(-P)")) {
                  if (clientData.lifeChecklist.length > 0) {
                    const lifeChecklistResult = await addChecklistToCard(
                      addCardResult.id,
                      "Product Checklist"
                    ).then((res) => {
                      return res;
                    });
                    for (let i = 0; i < clientData.lifeChecklist.length; i++) {
                      await trello.addItemToChecklist(
                        lifeChecklistResult.id,
                        clientData.lifeChecklist[i]
                      );
                    }
                  }
                }
                if (
                  jobTypeNew?.includes("(-V)") ||
                  jobTypeNew?.includes("(-E)")
                ) {
                  if (clientData.videoChecklist.length > 0) {
                    const videoChecklistResult = await addChecklistToCard(
                      addCardResult.id,
                      "Video Checklist"
                    ).then((res) => {
                      return res;
                    });
                    for (let i = 0; i < clientData.videoChecklist.length; i++) {
                      await trello.addItemToChecklist(
                        videoChecklistResult.id,
                        clientData.videoChecklist[i]
                      );
                    }
                  }
                }

                newJobText = `${newJobText} & Trello Card created! 🥳`;
                setDisableCloseModal(false);
                setNewJobAlertText(newJobText);
                setOpenNewJobAlert(true);
                newJobModalClose();
              } else {
                newJobText = `${newJobText} created! 🥳`;
                setDisableCloseModal(false);
                setNewJobAlertText(newJobText);
                setOpenNewJobAlert(true);
                newJobModalClose();
              }
            }}
          >
            Create Job
          </Button>
          <p style={{ fontSize: "12px" }}>* Required</p>
        </div>
      </div>
    </div>
  );

  const YearButton = ({ year }) => {
    return (
      <div
        onClick={() => {
          setJobData(null);
          setVisibleYear(year);
        }}
        style={{
          paddingTop: 15,
          paddingBottom: 15,
          paddingLeft: 40,
          paddingRight: 40,
          margin: 5,
          cursor: "pointer",
          color: "#ffffff",
          backgroundColor:
            year === visibleYear ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0.06)",
        }}
      >
        {year}
      </div>
    );
  };
  return (
    <div
      style={{
        overflowY: "scroll",
        position: "relative",
        height: "100%",
      }}
    >
      {/* <button onClick={() => { addClients(1) }}>JUST DO IT</button> */}
      <div
        style={{
          display: "flex",
          backgroundColor: `#${returnBackgroundColour}`,
          padding: 5,
          paddingTop: 15,
        }}
      >
        <div style={{ display: "flex", marginRight: 52 }}>
          {allYears &&
            allYears.map((year) => (
              <YearButton key={year} year={parseInt(year)} />
            ))}
        </div>

        {clientData ? (
          <div
            style={{
              display: "flex",
              // background: 'rgba(255, 255, 255, 0.8)',
              flexDirection: "column",
              borderRadius: 4,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Autocomplete
              options={clientData.map((c) => c.brand)}
              getOptionLabel={(opts) => opts}
              style={{ width: 260, minWidth: 100 }}
              value={clientSearch}
              // onInput={e => {
              //     if (!e.target.value) { setClientSearch(""); return };
              //     setClientSearch(e.target.value)
              // }}
              onChange={(e) => {
                if (!e.target.innerText) {
                  setClientSearch("");
                  return;
                }
                setClientSearch(e.target.innerText);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search For Client"
                  variant="outlined"
                />
              )}
            />
          </div>
        ) : null}
      </div>

      <TableContainer
        id="jobTable"
        component={Paper}
        className="tableFixHeader"
      >
        <Table stickyHeader className="jobsListTable" aria-label="simple table">
          <TableHead>
            <TableRow>
              {showDeleteJob ? <TableCell>Delete</TableCell> : null}
              <TableCell className="leftStickyTh">Job Number</TableCell>
              <TableCell>Client Name</TableCell>
              <TableCell>Multiple Days</TableCell>
              <TableCell>Type of Job</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>Finish Time</TableCell>
              <TableCell>Shoot Date</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Express</TableCell>
              <TableCell>Express Due Date</TableCell>
              <TableCell>Images Scheduled To be Shot</TableCell>
              <TableCell style={{ width: 250 }}>Photographer</TableCell>
              <TableCell style={{ width: 250 }}>Stylist</TableCell>
              <TableCell style={{ width: 250 }}>Operator</TableCell>
              <TableCell>Studio</TableCell>
              <TableCell>Shoot Special Requirements</TableCell>
              <TableCell>Invoicing Special Requirements</TableCell>
              <TableCell>Retouching Special Requirements</TableCell>
              <TableCell>Life - Full Day / Half Day</TableCell>
              <TableCell>Content - Full Day / Half Day</TableCell>
              <TableCell>Models</TableCell>
              <TableCell>Hair &amp; Makeup</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!JobData || !clientData ? (
              <Loading />
            ) : (
              JobData &&
              JobData.map((job) => {
                return (
                  <CreateTableRow
                    key={`${job.jobNumber}-${job.client}-${job.multipleDays}-${job.jobTypeFull}-mainTableRow`}
                    job={job}
                    staffData={staffData}
                    clientData={clientData}
                    setOpenNumbersOnly={setOpenNumbersOnly}
                    showDeleteJob={showDeleteJob}
                    setNewJobAlertText={setNewJobAlertText}
                    setOpenNewJobAlert={setOpenNewJobAlert}
                  />
                );
              })
            )}
            <LoadingMoreData />
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          position: "fixed",
          right: "40px",
          bottom: "40px",
          zIndex: "100",
        }}
      >
        <Fab
          color="primary"
          aria-label="Create New Job"
          onClick={() => {
            setNewJobModal(true);
          }}
        >
          <AddIcon />
        </Fab>
        <Modal
          open={newJobModal}
          onClose={newJobModalClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {newJobModalBody}
        </Modal>
      </div>

      <div>
        <Snackbar
          open={openNumbersOnly}
          autoHideDuration={6000}
          onClose={closeNumbersOnly}
        >
          <Alert onClose={closeNumbersOnly} severity="error">
            Numbers Only! - Settings wont be saved.
          </Alert>
        </Snackbar>
        <Snackbar
          open={openFillRequired}
          autoHideDuration={6000}
          onClose={closeFillRequired}
        >
          <Alert onClose={closeFillRequired} severity="error">
            Please fill all required fields
          </Alert>
        </Snackbar>
        <Snackbar
          open={openNewJobAlert}
          autoHideDuration={6000}
          onClose={closeNewJobAlert}
        >
          <Alert onClose={closeNewJobAlert} severity="success">
            {newJobAlertText}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default Jobs;

function CreateTableRow({
  job,
  clientData,
  staffData,
  setOpenNumbersOnly,
  showDeleteJob,
  setNewJobAlertText,
  setOpenNewJobAlert,
}) {
  const findStaff = (option, staffData) => {
    // if (option.preferredName) return `${option.preferredName}`;
    if (option.firstName) return `${option.firstName} ${option.lastName}`;
    for (let i = 0; i < staffData.length; i++) {
      if (staffData[i].id === option) {
        // if (staffData[i].preferredName) return `${staffData[i].preferredName}`;
        return `${staffData[i].firstName} ${staffData[i].lastName}`;
      }
    }
    return option;
  };

  // const [snap, setSnap] = useState(false);
  // const snap = false;

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const openMenu = Boolean(menuAnchorEl);
  const handleClickJobMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseJobMenu = () => {
    setMenuAnchorEl(null);
  };
  const createTrelloCard = async (handleCloseJobMenu) => {
    const addCardResult = await createCard(job.jobName, job.dueDate);

    firestore
      .collection("JobData")
      .doc(job.id)
      .update({ trelloCardID: addCardResult.id });

    if (job.express) {
      addLabelToCard(addCardResult.id, "5f0e8630fb83455e28da9908");
    }

    await addChecklistToCard(addCardResult.id, "Colour Corrections");
    await addChecklistToCard(addCardResult.id, "Colour Changes");
    await addChecklistToCard(addCardResult.id, "Retouching Notes");

    const clientData = await firestore
      .collection("ClientDatabase")
      .where("brand", "==", job.client)
      .get()
      .then((docs) => {
        return docs.docs[0].data();
      });

    if (clientData.generalChecklist.length > 0) {
      const generalChecklistResult = await addChecklistToCard(
        addCardResult.id,
        "General Checklist"
      ).then((res) => {
        return res;
      });
      for (let i = 0; i < clientData.generalChecklist.length; i++) {
        await trello.addItemToChecklist(
          generalChecklistResult.id,
          clientData.generalChecklist[i]
        );
      }
    }
    if (job.jobTypeFull?.includes("(-L)")) {
      if (clientData.lifeChecklist.length > 0) {
        const lifeChecklistResult = await addChecklistToCard(
          addCardResult.id,
          "Life Checklist"
        ).then((res) => {
          return res;
        });
        for (let i = 0; i < clientData.lifeChecklist.length; i++) {
          await trello.addItemToChecklist(
            lifeChecklistResult.id,
            clientData.lifeChecklist[i]
          );
        }
      }
    }
    if (job.jobTypeFull?.includes("(-P)")) {
      if (clientData.lifeChecklist.length > 0) {
        const lifeChecklistResult = await addChecklistToCard(
          addCardResult.id,
          "Product Checklist"
        ).then((res) => {
          return res;
        });
        for (let i = 0; i < clientData.lifeChecklist.length; i++) {
          await trello.addItemToChecklist(
            lifeChecklistResult.id,
            clientData.lifeChecklist[i]
          );
        }
      }
    }
    if (
      job.jobTypeFull?.includes("(-V)") ||
      job.jobTypeFull?.includes("(-E)")
    ) {
      if (clientData.videoChecklist.length > 0) {
        const videoChecklistResult = await addChecklistToCard(
          addCardResult.id,
          "Video Checklist"
        ).then((res) => {
          return res;
        });
        for (let i = 0; i < clientData.videoChecklist.length; i++) {
          await trello.addItemToChecklist(
            videoChecklistResult.id,
            clientData.videoChecklist[i]
          );
        }
      }
    }
    setNewJobAlertText("Trello card created! 🥳");
    setOpenNewJobAlert(true);
    handleCloseJobMenu();
  };

  const handleDeleteJobClick = (handleCloseJobMenu) => {
    handleCloseJobMenu();
    setDeleteJobModal(true);
  };
  const [deleteJobModal, setDeleteJobModal] = useState(false);
  const deleteJobModalClose = () => {
    setDeleteJobModal(false);
  };
  const permtextStyle = {
    textDecoration: "underline",
    fontWeight: "900",
  };
  const deleteJob = async () => {
    deleteJobModalClose();
    // setSnap(!snap)
    // setTimeout(async () => {
    if (job.trelloCardID) {
      await getCard("5e6881d6443bdb8b886e0eec", job.trelloCardID)
        .then((res) => {
          deleteCard(job.trelloCardID);
        })
        .catch((err) => console.log(err));
    }
    firestore.collection("JobData").doc(job.id).delete();
    // }, 5500);
  };
  const deleteJobModalBody = (
    <div
      style={{
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        position: "absolute",
        width: "300px",
        backgroundColor: "#ffffff",
        boxShadow: 0,
        padding: "20px",
        borderRadius: "5px",
        outline: "none",
      }}
    >
      <p>{job.jobName}</p>
      <p>Are you sure about this?</p>
      <p>
        Pressing delete will <span style={permtextStyle}>permanently</span>{" "}
        delete all of the job data &amp; also trello card.
      </p>
      <p>
        I can not emphasise the word{" "}
        <span style={permtextStyle}>permanently</span> enough.
      </p>
      <p>Gone forever.</p>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <Button
          variant="contained"
          style={{ backgroundColor: "#46c77b", color: "#FFFFFF" }}
          onClick={deleteJobModalClose}
        >
          GO BACK
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: "#e52e59", color: "#FFFFFF" }}
          onClick={deleteJob}
        >
          DELETE
        </Button>
      </div>
    </div>
  );

  const updateJobName = (
    jobNumberChange,
    clientChange,
    multipleDaysChange,
    jobTypeFullChange
  ) => {
    const jobTypeShort = jobTypeFullChange.match(/^.*\((-.*)\)/)[1];
    let jobName;
    if (multipleDaysChange) {
      jobName = `${jobNumberChange}-${clientChange}-${multipleDaysChange}${jobTypeShort}`;
    } else {
      jobName = `${jobNumberChange}-${clientChange}${jobTypeShort}`;
    }
    firestore
      .collection("JobData")
      .doc(job.id)
      .update(
        { jobName: jobName, jobTypeShort: jobTypeShort },
        { merge: true }
      );
    if (job.trelloCardID) {
      updateCardName(job.trelloCardID, jobName);
    }
  };
  const [jobNumber, setJobNumber] = useState(job.jobNumber);
  const [confirmJobNumberChangeModal, setConfirmJobNumberChangeModal] =
    useState(false);
  const openConfirmModal = debounce(() => {
    setConfirmJobNumberChangeModal(true);
  }, 900);
  const [changeJobNameValues, setChangeJobNameValues] = useState("");
  const jobNumberChangeModal = (
    <div
      style={{
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        position: "absolute",
        width: "300px",
        backgroundColor: "#ffffff",
        boxShadow: 0,
        padding: "20px",
        borderRadius: "5px",
        outline: "none",
      }}
    >
      <p>Are you sure you want to change the job number?</p>
      <p>
        Changing this to the wrong number can make the job get lost in the
        system.
      </p>
      <p>
        Please double check the numbers below and make any changes if needed.
      </p>

      <TextField
        label="Old Job Number"
        variant="outlined"
        defaultValue={job.jobNumber}
        inputProps={{ readOnly: true }}
        style={{ margin: "10px" }}
      />
      <TextField
        label="New Job Number"
        variant="outlined"
        defaultValue={changeJobNameValues}
        onChange={(e) => {
          if (isNaN(e.target.value)) {
            setOpenNumbersOnly(true);
          } else {
            setChangeJobNameValues(e.target.value);
          }
        }}
        style={{ margin: "10px" }}
      />
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <Button
          variant="contained"
          style={{ backgroundColor: "#46c77b", color: "#FFFFFF" }}
          onClick={() => {
            setJobNumber(job.jobNumber);
            setConfirmJobNumberChangeModal(false);
          }}
        >
          GO BACK
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: "#e52e59", color: "#FFFFFF" }}
          onClick={() => {
            updateFS(job.id, "jobNumber", parseInt(changeJobNameValues));
            updateJobName(
              parseInt(changeJobNameValues),
              job.client,
              job.multipleDays,
              job.jobTypeFull
            );
          }}
        >
          CONFIRM
        </Button>
      </div>
    </div>
  );
  const handleDuplicateJobClick = () => {
    let newJob = job;
    newJob.trelloCardID = "";
    newJob.multipleDays = "";
    newJob.jobTypeFull = "";
    newJob.catering = [];
    newJob.outsourcers = [
      {
        outsourcer: "",
        dueDate: null,
        sentDate: null,
        staff: "",
        lineItems: [{ type: "", images: "", price: "" }],
      },
    ];
    firestore.collection("JobData").doc().set(newJob);
  };
  return (
    <TableRow
      key={`${job.jobNumber}-${job.client}-${job.multipleDays}-${job.jobTypeFull}`}
    >
      <Modal open={deleteJobModal} onClose={deleteJobModalClose}>
        {deleteJobModalBody}
      </Modal>
      <Modal
        open={confirmJobNumberChangeModal}
        onClose={() => {
          setConfirmJobNumberChangeModal(false);
        }}
      >
        {jobNumberChangeModal}
      </Modal>
      {/* {showDeleteJob ? <TableCell><DeleteIcon style={{ color: '#ff0033', cursor: 'pointer', padding: '20px 50px 20px 50px' }} /></TableCell> : null} */}
      <TableCell
        className="leftSticky"
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            size="small"
            style={{
              width: 35,
              height: 35,
              marginRight: "10px",
              marginLeft: "-10px",
            }}
            onClick={handleClickJobMenu}
          >
            <MoreVertIcon
              style={{
                color: "#3f51b5",
                width: 25,
                height: 25,
              }}
            />
          </IconButton>
          <Menu
            anchorEl={menuAnchorEl}
            open={openMenu}
            onClose={handleCloseJobMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {/* {job.trelloCardID ? <MenuItem onClick={handleCloseJobMenu}>No Options</MenuItem> : <MenuItem onClick={() => { createTrelloCard(handleCloseJobMenu) }}>Create Trello Card</MenuItem>} */}
            {job.trelloCardID ? null : (
              <MenuItem
                onClick={() => {
                  createTrelloCard(handleCloseJobMenu);
                }}
              >
                Create Trello Card
              </MenuItem>
            )}

            <MenuItem onClick={handleDuplicateJobClick}>Duplicate Job</MenuItem>
            <MenuItem
              onClick={() => {
                handleDeleteJobClick(handleCloseJobMenu);
              }}
            >
              Delete Job
            </MenuItem>
          </Menu>
          <TextField
            id={`${job.jobNumber}-${job.client}-${job.multipleDays}-jobNumber`}
            label="Job Number"
            variant="outlined"
            value={jobNumber}
            style={{ width: "200px" }}
            onChange={(e) => {
              if (isNaN(e.target.value)) {
                setOpenNumbersOnly(true);
              } else {
                setChangeJobNameValues(parseInt(e.target.value));
                setJobNumber(parseInt(e.target.value));
                // updateFS1500(job.id, 'jobNumber', parseInt(e.target.value))
                // updateJobName(e.target.value, job.client, job.multipleDays, job.jobTypeFull)
                openConfirmModal();
              }
            }}
          />
        </div>

        {job?.trelloCardID ? null : (
          <div
            style={{ marginTop: "6px", fontSize: "12px", color: "#ff0033BF" }}
          >
            * Trello card not created
          </div>
        )}
      </TableCell>
      <TableCell>
        {clientData && clientData ? (
          <ClientSelector
            job={job}
            clientData={clientData}
            updateJobName={updateJobName}
          />
        ) : (
          "client"
        )}
      </TableCell>
      <TableCell>
        <TextField
          id={`${job.jobNumber}-${job.client}-${job.multipleDays}-multipleDays`}
          label="Multiple Days"
          variant="outlined"
          defaultValue={job.multipleDays}
          onChange={(e) => {
            updateFS(job.id, "multipleDays", e.target.value);
            updateJobName(
              job.jobNumber,
              job.client,
              e.target.value,
              job.jobTypeFull
            );
          }}
        />
      </TableCell>
      <TableCell>
        <Autocomplete
          id={`${job.jobNumber}-${job.client}-${job.multipleDays}-jobTypeFull`}
          options={jobTypes.map((j) => j.title)}
          getOptionLabel={(opts) => opts}
          style={{ width: 240, minWidth: 100 }}
          value={job.jobTypeFull}
          onChange={(e) => {
            if (!e.target.innerText) {
              updateFS(job.id, "jobTypeFull", null);
              updateJobName(job.jobNumber, job.client, job.multipleDays, null);
            } else {
              updateFS(job.id, "jobTypeFull", e.target.innerText);
              updateJobName(
                job.jobNumber,
                job.client,
                job.multipleDays,
                e.target.innerText
              );
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Job Type" variant="outlined" />
          )}
        />
      </TableCell>
      <TableCell>
        <TimeSelector
          job={job}
          jobTime={job.bookedStart}
          kind="bookedStart"
          id={job.id}
          labelText="Start Time"
        />
      </TableCell>
      <TableCell>
        <TimeSelector
          job={job}
          jobTime={job.bookedFinish}
          kind="bookedFinish"
          id={job.id}
          labelText="Finish Time"
        />
      </TableCell>
      <TableCell>
        <DateSelector
          job={job}
          jobDate={job.shootDate}
          kind="shootDate"
          id={job.id}
          labelText="Shoot Date"
        />
      </TableCell>
      <TableCell>
        <DateSelector
          job={job}
          jobDate={job.dueDate}
          kind="dueDate"
          id={job.id}
          labelText="Due Date"
        />
      </TableCell>
      <TableCell>
        <ExpressCheckBox job={job} />
      </TableCell>
      <TableCell>
        <DateSelector
          job={job}
          jobDate={job.expressDueDate}
          kind="expressDueDate"
          id={job.id}
          labelText="Express Date"
        />
      </TableCell>
      <TableCell>
        <TextField
          id={`${job.jobNumber}-${job.client}-${job.multipleDays}-imagesScheduledToBeShot`}
          label="Images Scheduled"
          variant="outlined"
          defaultValue={job.imagesScheduledToBeShot}
          onChange={(e) => {
            updateFS(job.id, "imagesScheduledToBeShot", e.target.value);
          }}
        />
      </TableCell>
      <TableCell>
        <Autocomplete
          id={`${job.jobNumber}-${job.client}-${job.multipleDays}-photographer`}
          disablePortal
          style={{ width: 250 }}
          options={staffData}
          getOptionLabel={(option) => findStaff(option, staffData)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Photographer" />
          )}
          value={job.photographer}
          onChange={(e, value) => {
            updateFSNOW(job.id, "photographer", value.id || "");
          }}
        />
        {/* <TextField
					id={`${job.jobNumber}-${job.client}-${job.multipleDays}-photographer`}
					// label={job.jobTypeFull.includes('Video') ? "Videographer" : "Photographer"} //"Photographer"
					label="Photographer"
					variant="outlined"
					defaultValue={job.photographer}
					onChange={(e) => {
						updateFSNOW(job.id, 'photographer', e.target.value);
					}}
				/> */}
      </TableCell>
      <TableCell>
        <Autocomplete
          id={`${job.jobNumber}-${job.client}-${job.multipleDays}-stylist`}
          disablePortal
          style={{ width: 250 }}
          options={staffData}
          getOptionLabel={(option) => findStaff(option, staffData)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Stylist" />
          )}
          value={job.stylist}
          onChange={(e, value) => {
            updateFSNOW(job.id, "stylist", value.id || "");
          }}
        />
        {/* <TextField
					id={`${job.jobNumber}-${job.client}-${job.multipleDays}-stylist`}
					label="Stylist"
					variant="outlined"
					defaultValue={job.stylist}
					onChange={(e) => {
						updateFSNOW(job.id, 'stylist', e.target.value);
					}}
				/> */}
      </TableCell>
      <TableCell>
        <Autocomplete
          id={`${job.jobNumber}-${job.client}-${job.multipleDays}-stylist`}
          disablePortal
          style={{ width: 250 }}
          options={staffData}
          getOptionLabel={(option) => findStaff(option, staffData)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Operator" />
          )}
          value={job.operator}
          onChange={(e, value) => {
            updateFSNOW(job.id, "operator", value.id || "");
          }}
        />
        {/* <TextField
					id={`${job.jobNumber}-${job.client}-${job.multipleDays}-operator`}
					label="Operator"
					variant="outlined"
					defaultValue={job.operator}
					onChange={(e) => {
						updateFSNOW(job.id, 'operator', e.target.value);
					}}
				/> */}
      </TableCell>
      <TableCell>
        <Autocomplete
          id={`${job.jobNumber}-${job.client}-${job.multipleDays}-studio`}
          options={studios.map((s) => s.name)}
          getOptionLabel={(opts) => opts}
          style={{ width: 200, minWidth: 100 }}
          value={job.studio}
          onChange={(e) => {
            if (!e.target.innerText) {
              updateFS(job.id, "studio", null);
            } else {
              updateFS(job.id, "studio", e.target.innerText);
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Studio" variant="outlined" />
          )}
        />
      </TableCell>
      <TableCell>
        <SpecialRequirements job={job} kind="shootSpecialRequirements" />
      </TableCell>
      <TableCell>
        <SpecialRequirements job={job} kind="invoicingSpecialRequirements" />
      </TableCell>
      <TableCell>
        <SpecialRequirements job={job} kind="retouchingSpecialRequirements" />
      </TableCell>
      <TableCell>
        <Autocomplete
          id={`${job.jobNumber}-${job.client}-${job.multipleDays}-dayType`}
          options={dayTypes.map((s) => s.type)}
          getOptionLabel={(opts) => opts}
          style={{ width: 200, minWidth: 100 }}
          value={job.dayType}
          onChange={(e) => {
            if (!e.target.innerText) {
              updateFS(job.id, "dayType", null);
            } else {
              updateFS(job.id, "dayType", e.target.innerText);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Life - Day Length"
              variant="outlined"
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Autocomplete
          id={`${job.jobNumber}-${job.client}-${job.multipleDays}-creativeDayType`}
          options={dayTypes.map((s) => s.type)}
          getOptionLabel={(opts) => opts}
          style={{ width: 200, minWidth: 100 }}
          value={job.creativeDayType}
          onChange={(e) => {
            if (!e.target.innerText) {
              updateFS(job.id, "creativeDayType", null);
            } else {
              updateFS(job.id, "creativeDayType", e.target.innerText);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Content - Day Length"
              variant="outlined"
            />
          )}
        />
      </TableCell>
      <TableCell>
        <ModelModal job={job} />
      </TableCell>
      <TableCell>
        <HMUModal job={job} />
      </TableCell>
    </TableRow>
  );
}

function LoadingMoreData() {
  return (
    <TableRow key={"loadingMoreRow"}>
      {skeletonTableCell.map((i) => {
        return (
          <TableCell
            key={`loadingMoreCell${i}`}
            style={{ width: "185px", height: "81px" }}
          >
            <Skeleton
              variant="rect"
              key={`Skeleton${i}`}
              width={140}
              height={40}
              style={{
                display: "block",
                textAlign: "center",
                margin: "0 auto",
              }}
            />
          </TableCell>
        );
      })}
    </TableRow>
  );
}
function Loading() {
  return (
    <>
      {skeletonTableRow.map((j) => {
        return (
          <TableRow key={`Row${j}`}>
            {skeletonTableCell.map((i) => {
              return (
                <TableCell
                  key={`Cell${i}`}
                  style={{ width: "185px", height: "81px" }}
                >
                  <Skeleton
                    variant="rect"
                    key={`Skeleton${i}`}
                    width={140}
                    height={40}
                    style={{
                      display: "block",
                      textAlign: "center",
                      margin: "0 auto",
                    }}
                  />
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </>
  );
}
// function LoadingOLD() {
//     return (
//         <div style={{
//             position: 'absolute',
//             transform: 'translate(-50%, -50%)',
//             top: '50%',
//             left: `calc(50% + 100px)`,
//         }}>
//             <CircularProgress disableShrink style={{
//                 width: '200px',
//                 height: '200px',
//                 color: 'rgba(0, 152, 122, 1)'
//             }} />
//         </div>
//     )
// }

function parseModelTime(t) {
  try {
    return t.toDate();
  } catch (e) {
    return convertStringToDate(t);
  }
}
const HMU = ({ job, hmu, index, showDelete, setOpen }) => {
  const [callTime, setCallTime] = useState(parseModelTime(hmu.callTime));
  const [finishTime, setFinishTime] = useState(parseModelTime(hmu.finishTime));

  const deleteHMU = () => {
    firestore
      .collection("JobData")
      .doc(job.id)
      .update({ hmu: arrayRemove(hmu) });
    setOpen(false);
    setTimeout(() => {
      setOpen(true);
    }, 50);
  };
  return (
    <div className="modelContainerContainer">
      <div className="deleteModelBtn" style={{ display: showDelete }}>
        <Fab
          color="secondary"
          aria-label="Create New Job"
          size="medium"
          className=""
          onClick={deleteHMU}
        >
          <RemoveIcon />
        </Fab>
      </div>
      <div className="modelContainer">
        <TextField
          id={`${job.jobNumber}-${job.client}-${job.multipleDays}-hmuname${index}`}
          label="Name"
          variant="outlined"
          defaultValue={hmu.name}
          style={{
            minWidth: "180px",
          }}
          onChange={(e) => {
            job.hmu[index].name = e.target.value;
            updateFSNOW(job.id, `hmu`, job.hmu);
          }}
          className="model"
        />
        <TextField
          id={`${job.jobNumber}-${job.client}-${job.multipleDays}-hmuagency${index}`}
          label="Agency"
          variant="outlined"
          defaultValue={hmu.agency}
          style={{
            minWidth: "180px",
          }}
          onChange={(e) => {
            job.hmu[index].agency = e.target.value;
            updateFSNOW(job.id, `hmu`, job.hmu);
          }}
          className="model"
        />
        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            id={`${job.jobNumber}-${job.client}-${job.multipleDays}-hmucalltime${index}`}
            label="Call Time"
            inputVariant="outlined"
            value={callTime}
            style={{ width: 160, minWidth: 160 }}
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
            onChange={(e) => {
              setCallTime(e.toISOString());
              job.hmu[index].callTime = e.toISOString();
              updateFSNOW(job.id, `hmu`, job.hmu.toISOString());
            }}
            className="model"
          />
          <KeyboardTimePicker
            id={`${job.jobNumber}-${job.client}-${job.multipleDays}-hmufinishtime${index}`}
            label="Finish Time"
            inputVariant="outlined"
            value={finishTime}
            style={{ width: 160, minWidth: 160 }}
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
            onChange={(e) => {
              setFinishTime(e.toISOString());
              job.hmu[index].finishTime = e.toISOString();
              updateFSNOW(job.id, `hmu`, job.hmu.toISOString());
            }}
            className="model"
          />
        </MuiPickersUtilsProvider> */}
        <Autocomplete
          id={`${job.jobNumber}-${job.client}-${job.multipleDays}-bookedBy${index}`}
          options={bookedByTypes.map((f) => f.type)}
          getOptionLabel={(opts) => opts}
          style={{ width: 180, minWidth: 180 }}
          value={hmu.bookedBy}
          onChange={(e) => {
            if (!e.target.innerText) {
              job.hmu[index].bookedBy = null;
              updateFSNOW(job.id, `hmu`, job.hmu);
            } else {
              job.hmu[index].bookedBy = e.target.innerText;
              updateFSNOW(job.id, `hmu`, job.hmu);
            }
          }}
          className="model"
          renderInput={(params) => (
            <TextField {...params} label="Booked By" variant="outlined" />
          )}
        />
        <Autocomplete
          id={`${job.jobNumber}-${job.client}-${job.multipleDays}-paidBy${index}`}
          options={paidByTypes.map((f) => f.type)}
          getOptionLabel={(opts) => opts}
          style={{ width: 170, minWidth: 170 }}
          value={hmu.paidBy}
          onChange={(e) => {
            if (!e.target.innerText) {
              job.hmu[index].paidBy = null;
              updateFSNOW(job.id, `hmu`, job.hmu);
            } else {
              job.hmu[index].paidBy = e.target.innerText;
              updateFSNOW(job.id, `hmu`, job.hmu);
            }
          }}
          className="model"
          renderInput={(params) => (
            <TextField {...params} label="Paid By" variant="outlined" />
          )}
        />
      </div>
    </div>
  );
};
const HMUModal = ({ job }) => {
  const [open, setOpen] = useState(false);
  const [showDelete, setShowDelete] = useState("none");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const makeNewHMU = () => {
    firestore
      .collection("JobData")
      .doc(job.id)
      .update({ hmu: arrayUnion({ name: "" }) });
  };
  const showDeleteClick = () => {
    if (showDelete === "none") {
      setShowDelete("");
      setTimeout(() => {
        setShowDelete("none");
      }, 8000);
    } else if (showDelete === "") {
      setShowDelete("none");
    }
  };
  const body = (
    <div
      style={{
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        position: "absolute",
        minWidth: "750px",
        backgroundColor: "#ffffff",
        boxShadow: 0,
        padding: "20px",
        borderRadius: "5px",
        outline: "none",
      }}
    >
      <Fab
        color="secondary"
        aria-label="Show Delete HMU"
        size="medium"
        className="addNewModelBtn"
        onClick={showDeleteClick}
      >
        <DeleteIcon />
      </Fab>
      <Fab
        color="primary"
        aria-label="Create New HMU"
        size="medium"
        className="addNewModelBtn"
        onClick={makeNewHMU}
      >
        <AddIcon />
      </Fab>
      <h3>Hair and Makeup</h3>
      {job.hmu?.length > 0 ? (
        job.hmu.map((hmu, index) => (
          <HMU
            key={`${job.jobNumber}-${job.client}-${job.multipleDays}-model${index}`}
            job={job}
            hmu={hmu}
            index={index}
            showDelete={showDelete}
            setOpen={setOpen}
          />
        ))
      ) : (
        <Noop />
      )}
    </div>
  );

  return (
    <div>
      <div className="fill centerFlex pointer" onClick={handleOpen}>
        {modelsText(job.hmu, "HMU")}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};
const Model = ({ job, model, index, showDelete, setOpen }) => {
  const [callTime, setCallTime] = useState(parseModelTime(model.callTime));
  const [finishTime, setFinishTime] = useState(
    parseModelTime(model.finishTime)
  );

  const deleteModel = () => {
    firestore
      .collection("JobData")
      .doc(job.id)
      .update({ models: arrayRemove(model) });
    setOpen(false);
    setTimeout(() => {
      setOpen(true);
    }, 50);
  };
  return (
    <div className="modelContainerContainer">
      <div className="deleteModelBtn" style={{ display: showDelete }}>
        <Fab
          color="secondary"
          aria-label="Create New Job"
          size="medium"
          className=""
          onClick={deleteModel}
        >
          <RemoveIcon />
        </Fab>
      </div>
      <div className="modelContainer">
        <TextField
          id={`${job.jobNumber}-${job.client}-${job.multipleDays}-modelname${index}`}
          label="Model Name"
          variant="outlined"
          defaultValue={model.name}
          style={{
            minWidth: "180px",
          }}
          onChange={(e) => {
            job.models[index].name = e.target.value;
            updateFSNOW(job.id, `models`, job.models);
          }}
          className="model"
        />
        <TextField
          id={`${job.jobNumber}-${job.client}-${job.multipleDays}-modelagency${index}`}
          label="Agency"
          variant="outlined"
          defaultValue={model.agency}
          style={{
            minWidth: "180px",
          }}
          onChange={(e) => {
            job.models[index].agency = e.target.value;
            updateFSNOW(job.id, `models`, job.models);
          }}
          className="model"
        />
        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            id={`${job.jobNumber}-${job.client}-${job.multipleDays}-modelcalltime${index}`}
            label="Call Time"
            inputVariant="outlined"
            value={callTime}
            style={{ width: 160, minWidth: 160 }}
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
            onChange={(e) => {
              setCallTime(e.toISOString());
              job.models[index].callTime = e.toISOString();
              updateFSNOW(job.id, `models`, job.models.toISOString());
            }}
            className="model"
          />
          <KeyboardTimePicker
            id={`${job.jobNumber}-${job.client}-${job.multipleDays}-modelfinishtime${index}`}
            label="Finish Time"
            inputVariant="outlined"
            value={finishTime}
            style={{ width: 160, minWidth: 160 }}
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
            onChange={(e) => {
              setFinishTime(e.toISOString());
              job.models[index].finishTime = e.toISOString();
              updateFSNOW(job.id, `models`, job.models.toISOString());
            }}
            className="model"
          />
        </MuiPickersUtilsProvider> */}
        <Autocomplete
          id={`${job.jobNumber}-${job.client}-${job.multipleDays}-faceNoFace${index}`}
          options={faceNoFaceTypes.map((f) => f.type)}
          getOptionLabel={(opts) => opts}
          style={{ width: 140, minWidth: 140 }}
          value={model.faceNoFace}
          onChange={(e) => {
            if (!e.target.innerText) {
              job.models[index].faceNoFace = null;
              updateFSNOW(job.id, `models`, job.models);
            } else {
              job.models[index].faceNoFace = e.target.innerText;
              updateFSNOW(job.id, `models`, job.models);
            }
          }}
          className="model"
          renderInput={(params) => (
            <TextField {...params} label="Face / NoFace" variant="outlined" />
          )}
        />
        <Autocomplete
          id={`${job.jobNumber}-${job.client}-${job.multipleDays}-bookedBy${index}`}
          options={bookedByTypes.map((f) => f.type)}
          getOptionLabel={(opts) => opts}
          style={{ width: 180, minWidth: 180 }}
          value={model.bookedBy}
          onChange={(e) => {
            if (!e.target.innerText) {
              job.models[index].bookedBy = null;
              updateFSNOW(job.id, `models`, job.models);
            } else {
              job.models[index].bookedBy = e.target.innerText;
              updateFSNOW(job.id, `models`, job.models);
            }
          }}
          className="model"
          renderInput={(params) => (
            <TextField {...params} label="Booked By" variant="outlined" />
          )}
        />
        <Autocomplete
          id={`${job.jobNumber}-${job.client}-${job.multipleDays}-paidBy${index}`}
          options={paidByTypes.map((f) => f.type)}
          getOptionLabel={(opts) => opts}
          style={{ width: 170, minWidth: 170 }}
          value={model.paidBy}
          onChange={(e) => {
            if (!e.target.innerText) {
              job.models[index].paidBy = null;
              updateFSNOW(job.id, `models`, job.models);
            } else {
              job.models[index].paidBy = e.target.innerText;
              updateFSNOW(job.id, `models`, job.models);
            }
          }}
          className="model"
          renderInput={(params) => (
            <TextField {...params} label="Paid By" variant="outlined" />
          )}
        />
      </div>
    </div>
  );
};
const ModelModal = ({ job }) => {
  const [open, setOpen] = useState(false);
  const [showDelete, setShowDelete] = useState("none");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const makeNewModel = () => {
    firestore
      .collection("JobData")
      .doc(job.id)
      .update({ models: arrayUnion({ name: "" }) });
  };
  const showDeleteClick = () => {
    if (showDelete === "none") {
      setShowDelete("");
      setTimeout(() => {
        setShowDelete("none");
      }, 8000);
    } else if (showDelete === "") {
      setShowDelete("none");
    }
  };
  const body = (
    <div
      style={{
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        position: "absolute",
        minWidth: "750px",
        maxHeight: "80%",
        overflow: "auto",
        backgroundColor: "#ffffff",
        boxShadow: 0,
        padding: "20px",
        borderRadius: "5px",
        outline: "none",
      }}
    >
      <Fab
        color="secondary"
        aria-label="Show Delete Models"
        size="medium"
        className="addNewModelBtn"
        onClick={showDeleteClick}
      >
        <DeleteIcon />
      </Fab>
      <Fab
        color="primary"
        aria-label="Create New Model"
        size="medium"
        className="addNewModelBtn"
        onClick={makeNewModel}
      >
        <AddIcon />
      </Fab>
      <h3>Models</h3>
      {job.models?.length > 0 ? (
        job.models.map((model, index) => (
          <Model
            key={`${job.jobNumber}-${job.client}-${job.multipleDays}-model${index}`}
            job={job}
            model={model}
            index={index}
            showDelete={showDelete}
            setOpen={setOpen}
          />
        ))
      ) : (
        <Noop />
      )}
    </div>
  );

  return (
    <div>
      <div className="fill centerFlex pointer" onClick={handleOpen}>
        {modelsText(job.models, "Model")}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

const Noop = () => {
  return <div></div>;
};

const SpecialRequirements = ({ job, kind }) => {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div
      style={{
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        position: "absolute",
        minWidth: "50%",
        backgroundColor: "#ffffff",
        boxShadow: 0,
        padding: "20px",
        borderRadius: "5px",
        outline: "none",
      }}
    >
      <TextField
        id={`${job.jobNumber}-${job.client}-${job.multipleDays}-${kind}`}
        label={camelToTitle(kind)}
        variant="outlined"
        multiline
        defaultValue={job[kind]}
        style={{ width: "100%", height: "100%" }}
        onChange={(e) => {
          updateFS(job.id, kind, e.target.value);
        }}
      />
    </div>
  );

  return (
    <div>
      <div className="ellipsis fill" onClick={handleOpen}>
        <span
          style={{
            display: "block",
            fontWeight: "600",
            marginTop: "5px",
            marginBottom: "10px",
          }}
        >
          {camelToTitle(kind)}
        </span>
        {job[kind]}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

const ExpressCheckBox = ({ job }) => {
  const [expressChecked, setExpressChecked] = useState(job.express);
  const expressChange = (e) => {
    setExpressChecked(e.target.checked);
    updateFS(job.id, "express", e.target.checked);
    // setExpressLabel(job.trelloCardID, e.target.checked);
  };
  return (
    <Checkbox
      checked={expressChecked || false}
      onChange={expressChange}
      color="primary"
      inputProps={{ "aria-label": "primary checkbox" }}
    />
  );
};

const ClientSelector = ({ job, clientData, updateJobName }) => {
  const updateDB = (e) => {
    if (!e.target.value) {
      if (e.target.innerText) {
        job.client = e.target.innerText;
        updateFS(job.id, `client`, e.target.innerText);
        updateJobName(
          job.jobNumber,
          e.target.innerText,
          job.multipleDays,
          job.jobTypeFull
        );
      } else {
        job.client = null;
        updateFS(job.id, `client`, null);
        updateJobName(job.jobNumber, null, job.multipleDays, job.jobTypeFull);
      }
    } else {
      job.client = e.target.value;
      updateFS(job.id, `client`, e.target.value);
      updateJobName(
        job.jobNumber,
        e.target.value,
        job.multipleDays,
        job.jobTypeFull
      );
    }
  };
  return (
    <Autocomplete
      id={`${job.jobNumber}-${job.client}-${job.multipleDays}-client`}
      options={clientData.map((c) => c.brand)}
      getOptionLabel={(opts) => opts}
      style={{ width: 260, minWidth: 100 }}
      value={job.client}
      onInput={(e) => {
        updateDB(e);
      }}
      onChange={(e) => {
        updateDB(e);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Client" variant="outlined" />
      )}
    />
  );
};

const TimeSelector = ({ job, jobTime, kind, labelText, id }) => {
  if (!jobTime) {
    jobTime = null;
  }
  const [time, setTime] = useState(jobTime);

  return (
    // <MuiPickersUtilsProvider utils={DateFnsUtils}>
    //   <KeyboardTimePicker
    //     id={`${job.jobNumber}-${job.client}-${job.multipleDays}-${kind}`}
    //     label={labelText}
    //     variant="inline"
    //     inputVariant="outlined"
    //     value={time}
    //     style={{ width: "180px" }}
    //     onChange={(d) => {
    //       if (job.shootDate) {
    //         let bs = new Date(job.shootDate);
    //         bs.setHours(d.getHours());
    //         bs.setMinutes(d.getMinutes());
    //         d = bs;
    //       }
    //       updateFS(id, kind, d.toISOString());
    //       setTime(d.toISOString());
    //     }}
    //   />
    // </MuiPickersUtilsProvider>
    (<></>)
  );
};
const DateSelector = ({ job, jobDate, kind, labelText, id }) => {
  const [date, setDate] = useState(jobDate);
  return (
    // <MuiPickersUtilsProvider utils={DateFnsUtils}>
    //   <KeyboardDatePicker
    //     disableToolbar
    //     autoOk={true}
    //     variant="inline"
    //     inputVariant="outlined"
    //     format="dd/MM/yyyy"
    //     style={{ width: "180px" }}
    //     id={`${job.jobNumber}-${job.client}-${job.multipleDays}-${kind}`}
    //     label={labelText}
    //     value={date || null}
    //     onChange={(d) => {
    //       updateFS(id, kind, d.toISOString());
    //       setDate(d.toISOString());
    //       if (kind === "dueDate") {
    //         // console.log(job)
    //         if (job.trelloCardID) {
    //           updateCardDueDate(job.trelloCardID, d.toISOString());
    //         }
    //       }
    //     }}
    //     KeyboardButtonProps={{
    //       "aria-label": "change date",
    //     }}
    //   />
    // </MuiPickersUtilsProvider>
    (<></>)
  );
};

// const returnExpress = (express) => {
//     if (typeof express === 'string') { express === 'true' ? express = true : express = false }
//     return express
// }

// const parseDate = (date) => {
//     if (!date) { return null }
//     const datepattern = new RegExp(/\D* \d\d\/\d\d\/\d\d\d\d/)
//     let datetmp
//     try {
//         datepattern.test(date) ? datetmp = parseit(date) : datetmp = date.toDate()
//         datetmp = new Date(datetmp)
//     } catch (e) {
//         datetmp = null
//     }
//     // return 'ss'
//     return datetmp

//     function parseit(date) {
//         const replace = date.replace(/\D\D\D /, '')
//         const split = replace.split('/')
//         let newDate = `${split[2]}-${split[1]}-${split[0]}`
//         newDate = newDate.toString().replace(/ /g, '')
//         return newDate
//     }
// }

// const updateFS1500 = debounce(async (id, field, value) => {
//     firestore.collection('JobData').doc(id).set({ [field]: value }, { merge: true });
// }, 1500);
const updateFS = debounce(async (id, field, value) => {
  firestore
    .collection("JobData")
    .doc(id)
    .set({ [field]: value }, { merge: true });
}, 800);
const updateFSNOW = debounce(async (id, field, value) => {
  firestore
    .collection("JobData")
    .doc(id)
    .set({ [field]: value }, { merge: true });
}, 0);

// const updateFSJobType = debounce(async (id, jobTypeFull, jobTypeShort, largeJobType) => {
//     firestore.collection('JobData').doc(id).set({ 'jobTypeFull': jobTypeFull }, { merge: true });
//     firestore.collection('JobData').doc(id).set({ 'jobTypeShort': jobTypeShort }, { merge: true });
//     firestore.collection('JobData').doc(id).set({ 'largeJobType': largeJobType }, { merge: true });
// }, 800);

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function camelToTitle(text) {
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

function modelsText(arr, kind) {
  if (arr) {
    if (arr.length === 1) {
      return `1 ${kind}`;
    } else return `${arr.length} ${kind}s`;
  } else return `0 ${kind}s`;
}

function convertStringToDate(str) {
  const d = new Date();
  try {
    const match = str.match(/(\d+)(:|\.)(\d+) ?(\w+)/);
    const hours = /am/i.test(match[4])
      ? parseInt(match[1], 10)
      : parseInt(match[1], 10) + 12;
    const minutes = parseInt(match[3], 10);
    d.setHours(hours, minutes, 0, 0);
  } catch (e) {
    return null;
  }
  return d;
}

// const setExpressLabel = async (cardId, value) => {
//   if (value) {
//     addLabelToCard(cardId, "5f0e8630fb83455e28da9908");
//   } else {
//     const key = "8991105779d1e51b2d00fb7d0e53fdf4";
//     const token =
//       "bd2df1f9229a34b63c8a5a5f59d75f3529f13475b8d0e27b5e3065e6252a987a";
//     const res = await fetch(
//       `https://api.trello.com/1/cards/${cardId}/idLabels/5f0e8630fb83455e28da9908?key=${key}&token=${token}`,
//       {
//         method: "DELETE",
//         mode: "cors",
//         headers: { "Content-Type": "application/json" },
//       }
//     ).then((res) => {
//       return res.json();
//     });
//     return res;
//   }
// };

const updateCardDueDate = async (cardId, value) => {
  const key = "8991105779d1e51b2d00fb7d0e53fdf4";
  const token =
    "bd2df1f9229a34b63c8a5a5f59d75f3529f13475b8d0e27b5e3065e6252a987a";
  const res = await fetch(
    `https://api.trello.com/1/cards/${cardId}?key=${key}&token=${token}`,
    {
      method: "PUT",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ due: value }),
    }
  ).then((res) => {
    return res.json();
  });
  return res;
};
const updateCardName = async (cardId, value) => {
  const key = "8991105779d1e51b2d00fb7d0e53fdf4";
  const token =
    "bd2df1f9229a34b63c8a5a5f59d75f3529f13475b8d0e27b5e3065e6252a987a";
  const res = await fetch(
    `https://api.trello.com/1/cards/${cardId}/name?key=${key}&token=${token}`,
    {
      method: "PUT",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ value }),
    }
  ).then((res) => {
    return res.json();
  });
  return res;
};
const getCard = async (boardId, cardId) => {
  const key = "8991105779d1e51b2d00fb7d0e53fdf4";
  const token =
    "bd2df1f9229a34b63c8a5a5f59d75f3529f13475b8d0e27b5e3065e6252a987a";
  const res = await fetch(
    `https://api.trello.com/1/boards/${boardId}/cards/${cardId}?key=${key}&token=${token}`,
    {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    }
  ).then((res) => {
    return res.json();
  });
  return res;
};
const deleteCard = async (cardId) => {
  const key = "8991105779d1e51b2d00fb7d0e53fdf4";
  const token =
    "bd2df1f9229a34b63c8a5a5f59d75f3529f13475b8d0e27b5e3065e6252a987a";
  const res = await fetch(
    `https://api.trello.com/1/cards/${cardId}?key=${key}&token=${token}`,
    {
      method: "DELETE",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    }
  ).then((res) => {
    return res.json();
  });
  return res;
};
const addChecklistToCard = async (cardId, checklistName) => {
  const key = "8991105779d1e51b2d00fb7d0e53fdf4";
  const token =
    "bd2df1f9229a34b63c8a5a5f59d75f3529f13475b8d0e27b5e3065e6252a987a";
  const res = await fetch(
    `https://api.trello.com/1/cards/${cardId}/checklists?key=${key}&token=${token}`,
    {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: checklistName,
      }),
    }
  ).then((res) => {
    return res.json();
  });
  return res;
};
const addLabelToCard = async (cardId, labelId) => {
  const key = "8991105779d1e51b2d00fb7d0e53fdf4";
  const token =
    "bd2df1f9229a34b63c8a5a5f59d75f3529f13475b8d0e27b5e3065e6252a987a";
  const res = await fetch(
    `https://api.trello.com/1/cards/${cardId}/idLabels?key=${key}&token=${token}`,
    {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        value: labelId,
      }),
    }
  ).then((res) => {
    return res.json();
  });
  return res;
};
const createCard = async (cardName, dueDate) => {
  const key = "8991105779d1e51b2d00fb7d0e53fdf4";
  const token =
    "bd2df1f9229a34b63c8a5a5f59d75f3529f13475b8d0e27b5e3065e6252a987a";
  const res = await fetch(
    `https://api.trello.com/1/cards?key=${key}&token=${token}`,
    {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: cardName,
        idList: "5f081763cd97556d03075c86",
        // idList: "5e6881da503fc83c4451a947", // To Shoot List
        pos: "bottom",
        due: setDueTime(dueDate) || "",
        dueComplete: "false",
        keepFromSource: "all",
      }),
    }
  ).then((res) => {
    return res.json();
  });
  return res;
};
const setDueTime = (d) => {
  let date = new Date(d);
  date.setMinutes("30");
  date.setHours("17");
  return date;
};
