import React, { useState, useEffect, useCallback } from "react";
import TextField from "@mui/material/TextField";
import ReplayIcon from "@mui/icons-material/Replay";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Switch from "@mui/material/Switch";
import MarkdownNotesEditor from "./MarkdownNotesEditor";
import OldInvoicing from "./OldInvoicing";
import { useAtom } from "jotai";
import { themeAtom } from "../../../-Atoms";
import { v4 as uuidv4 } from "uuid";
import BreakdownItem from "./Invoicing Components/BreakdownItem";
import BackgroundRolls from "./Invoicing Components/BackgroundRolls";
import VideoEditingBreakdown from "./Invoicing Components/VideoEditingBreakdown";
import Catering from "./Invoicing Components/Catering";
import Extras from "./Invoicing Components/Extras";
import JobSpecifications from "./Invoicing Components/JobSpecifications";
import Staff from "./Invoicing Components/Staff";
import Dates from "./Invoicing Components/Dates";
import debounce from "lodash.debounce";
import { useDynamicDebounceCallback } from "react-dynamic-debounce";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import AdvancedTextField from "../../../GlobalComponents/AdvancedTextField";

const Invoicing = ({
  jobData,
  staffData,
  findStaff,
  clientData,
  firebase,
  isDarkMode,
  setStopModalClose,
}) => {
  // console.log(setStopModalClose)
  const [theme] = useAtom(themeAtom);
  const updateFSNow = (firebase, id, field, value) => {
    firebase
      .firestore()
      .collection("JobData")
      .doc(id)
      .update({ [field]: value }, { merge: true })
      .catch((error) => {
        console.log(error);
      });
  };

  const [updateFS] = useDynamicDebounceCallback(updateFSNow, {
    defaultDelay: 1000,
    maxDelay: 6000,
    minSamples: 6,
    maxSamples: 4,
    delayFunction: (averageGap) => Math.floor(averageGap + 300),
  });

  const [invoicingSpecialRequirements, setInvoicingSpecialRequirements] =
    useState(jobData.invoicingSpecialRequirements);
  const [imageBreakdown, setImageBreakdown] = useState(
    jobData.imageBreakdown || []
  );
  useEffect(() => {
    setInvoicingSpecialRequirements(jobData.invoicingSpecialRequirements);
    setImageBreakdown(jobData.imageBreakdown);
  }, [jobData]);

  const [invoicingNotes] = useState(clientData.invoicingNotes);

  const titleStyle = {
    marginLeft: 30,
    marginRight: 20,
    marginBottom: 0,
    marginTop: 10,
    color: theme === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "bold",
    fontSize: "1.1rem",
    lineHeight: "1.4375em",
    letterSpacing: "0.00938em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <MarkdownNotesEditor
          label="Invoicing Notes"
          jobValue={invoicingSpecialRequirements}
          setJobValue={(e) => {
            setInvoicingSpecialRequirements(e);
          }}
          saveJobValue={(e) => {
            setInvoicingSpecialRequirements(e);
            updateFS(firebase, jobData.id, "invoicingSpecialRequirements", e);
          }}
          clientValue={invoicingNotes}
          isDarkMode={isDarkMode}
        />
      </div>

      <JobSpecifications
        titleStyle={titleStyle}
        jobData={jobData}
        clientData={clientData}
      />

      {imageBreakdown ? (
        <NewInvoicing
          jobData={jobData}
          staffData={staffData}
          findStaff={findStaff}
          clientData={clientData}
          firebase={firebase}
          isDarkMode={isDarkMode}
          titleStyle={titleStyle}
          updateFS={updateFS}
          setStopModalClose={setStopModalClose}
        />
      ) : (
        <OldInvoicing
          jobData={jobData}
          staffData={staffData}
          findStaff={findStaff}
          clientData={clientData}
          firebase={firebase}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
};

const NewInvoicing = ({
  jobData,
  staffData,
  findStaff,
  clientData,
  firebase,
  isDarkMode,
  titleStyle,
  updateFS,
  setStopModalClose,
}) => {
  const [theme] = useAtom(themeAtom);
  const [isTyping, setIsTyping] = useState(false);

  const [imageBreakdown, setImageBreakdown] = useState(
    jobData.imageBreakdown || []
  );
  const [imageBreakdownClient, setImageBreakdownClient] = useState(
    clientData.imageBreakdown || []
  );

  useEffect(() => {
    setImageBreakdownClient(clientData.imageBreakdown || []);
  }, [clientData]);

  const [imagesProcessedNumbers, setImagesProcessedNumbers] = useState(
    jobData.imagesProcessedNumbers
  );
  const [, setImagesProcessed] = useState(jobData.imagesProcessed);
  const [imagesSupplied, setImagesSupplied] = useState(jobData.imagesSupplied);

  const [, setImagesOverAllocation] = useState(jobData.imagesOverAllocation);
  const [jobTypeFull, setJobTypeFull] = useState(jobData.jobTypeFull);

  const [, setImagesProcessedNumbersError] = useState(true);
  const [, setImagesSuppliedError] = useState(true);
  const [retoucherError, setRetoucherError] = useState(true);
  const [retouchingTimeError, setRetouchingTimeError] = useState(
    jobTypeFull?.includes("(-V)") || jobTypeFull?.includes("(-E)")
      ? true
      : false
  );
  useEffect(() => {
    imagesProcessedNumbers
      ? setImagesProcessedNumbersError(false)
      : setImagesProcessedNumbersError(true);
  }, [imagesProcessedNumbers]);
  useEffect(() => {
    imagesSupplied
      ? setImagesSuppliedError(false)
      : setImagesSuppliedError(true);
  }, [imagesSupplied]);

  const [retoucher, setRetoucher] = useState("");
  const [retouchingTime, setRetouchingTime] = useState(jobData.retouchingTime);
  const [bookedStart, setBookedStart] = useState(jobData.bookedStart);
  const [bookedFinish, setBookedFinish] = useState(jobData.bookedFinish);
  const [startTime, setStartTime] = useState(jobData.startTime);
  const [finishTime, setFinishTime] = useState(jobData.finishTime);
  const [overtime, setOvertime] = useState(jobData.overtime);
  const [catering, setCatering] = useState(jobData.catering || []);

  const [videoTimeEstimate, setVideoTimeEstimate] = useState(
    jobData.videoTimeEstimate || []
  );
  const [videoNotes, setVideoNotes] = useState(jobData.videoNotes || []);

  const [backgroundRolls, setBackgroundRolls] = useState(
    jobData.backgroundRolls
  );
  const [videoEditingBreakdown, setVideoEditingBreakdown] = useState(
    jobData.videoEditingBreakdown
  );

  const [cStrings, setCStrings] = useState(jobData.cStrings);
  const [nippleCovers, setNippleCovers] = useState(jobData.nippleCovers);
  const [printedPages, setPrintedPages] = useState(jobData.printedPages);

  const [operator, setOperator] = useState(jobData.operator);
  const [additionalOperators, setAdditionalOperators] = useState(
    jobData.additionalOperators || []
  );

  useEffect(() => {
    retoucher ? setRetoucherError(false) : setRetoucherError(true);
  }, [retoucher]);

  useEffect(() => {
    if (jobTypeFull?.includes("(-V)") || jobTypeFull?.includes("(-E)")) {
      retouchingTime
        ? setRetouchingTimeError(false)
        : setRetouchingTimeError(true);
    }
  }, [retouchingTime, jobTypeFull]);

  useEffect(() => {
    jobTypeFull?.includes("(-V)") || jobTypeFull?.includes("(-E)")
      ? setRetouchingTimeError(true)
      : setRetouchingTimeError(false);
  }, [jobTypeFull]);

  const textFieldStyle = {
    margin: "6px",
  };

  const handleValueChange = (e, setValue, kind) => {
    setValue(e.target.value);
    jobData[kind] = e.target.value;
    updateFS(firebase, jobData.id, kind, e.target.value);
  };

  const handleDebouncedChange = useCallback(
    debounce((field, value) => {
      updateFS(firebase, jobData.id, field, value);
      setIsTyping(false);
    }, 500),
    [firebase, jobData.id]
  );

  const handleChange = (e, field, setValue) => {
    const value = e.target.value;
    setValue(value);
    setIsTyping(true);
    handleDebouncedChange(field, value);
  };

  const [hasHiddenItems, setHasHiddenItems] = useState(false);
  const [showHiddenItems, setShowHiddenItems] = useState(false);
  const handleShowHiddenToggle = (e) => {
    setShowHiddenItems(e.target.checked);
  };

  useEffect(() => {
    const hiddenItemsExist = hasHiddenItemsInArray(imageBreakdown);
    setHasHiddenItems(hiddenItemsExist);
  }, [imageBreakdown]);

  function hasHiddenItemsInArray(array) {
    return array.some((item) => item.itemHidden === true);
  }

  useEffect(() => {
    setImageBreakdown(jobData.imageBreakdown);
    setImagesProcessed(jobData.imagesProcessed);
    setImagesSupplied(jobData.imagesSupplied);
    setBackgroundRolls(jobData.backgroundRolls);
    setVideoEditingBreakdown(jobData.videoEditingBreakdown);
    setCStrings(jobData.cStrings);
    setNippleCovers(jobData.nippleCovers);
    setPrintedPages(jobData.printedPages);
    setOperator(jobData.operator);
    setAdditionalOperators(jobData.additionalOperators || []);
    setRetoucher(jobData.retoucher);
    setRetouchingTime(jobData.retouchingTime);
    setBookedStart(jobData.bookedStart);
    setBookedFinish(jobData.bookedFinish);
    setStartTime(jobData.startTime);
    setFinishTime(jobData.finishTime);
    setOvertime(jobData.overtime);
    setCatering(jobData.catering || []);
    setJobTypeFull(jobData.jobTypeFull);
    setImagesOverAllocation(jobData.imagesOverAllocation);
    setImagesProcessedNumbers(jobData.imagesProcessedNumbers);
    setVideoTimeEstimate(jobData.videoTimeEstimate);
    setVideoNotes(jobData.videoNotes);
  }, [jobData]);

  const addNewBreakdownItem = () => {
    const newitemObj = {
      id: uuidv4(),
      description: "",
      jobTypesFilter: [],
      quantityProcessed: "",
      filesInPost: "",
      quantitySupplied: "",
      expectedPerHour: "",
      costPerFile: "",
      existingItem: false,
      itemHidden: false,
      kind: "",
    };
    imageBreakdown.push(newitemObj);
    setImageBreakdown([...imageBreakdown]);
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ imageBreakdown: imageBreakdown }, { merge: true })
      .catch((e) => console.log(e));
  };

  const [showRemoveBreakdownItem, setShowRemoveBreakdownItem] = useState(false);
  const toggleRemoveBreakdownItem = () => {
    setShowRemoveBreakdownItem(true);
    setTimeout(() => {
      setShowRemoveBreakdownItem(false);
    }, 6000);
  };

  const [totalProccesed, setTotalProccesed] = useState("");
  const [totalExpected, setTotalExpected] = useState("");
  const [totalSupplied, setTotalSupplied] = useState("");

  useEffect(() => {
    try {
      let tp = 0;
      let te = 0;
      let ts = 0;
      if (isVideoJob(jobData)) {
        imageBreakdown.forEach((bi) => {
          if (bi.quantityProcessed) {
            tp = parseInt(tp) + parseInt(bi.quantityProcessed);
          }
          if (bi.filesInPost) {
            te = parseInt(te) + parseInt(bi.filesInPost);
          }
          if (bi.quantitySupplied) {
            ts = parseInt(ts) + parseInt(bi.quantitySupplied);
          }
          tp === 0 ? setTotalProccesed("") : setTotalProccesed(tp);
          te === 0 ? setTotalExpected("") : setTotalExpected(te);
          ts === 0 ? setTotalSupplied("") : setTotalSupplied(ts);
        });
      } else {
        imageBreakdown.forEach((bi) => {
          if (!bi.dontAddToTotalSupplied) {
            if (bi.quantityProcessed) {
              tp = parseInt(tp) + parseInt(bi.quantityProcessed);
              te = parseInt(te) + parseInt(bi.quantityProcessed);
            }
            if (bi.filesInPost) {
              te = parseInt(te) + parseInt(bi.filesInPost);
            }
            if (bi.quantitySupplied) {
              ts = parseInt(ts) + parseInt(bi.quantitySupplied);
            }
          }
        });
        tp === 0 ? setTotalProccesed("") : setTotalProccesed(tp);
        te === 0 ? setTotalExpected("") : setTotalExpected(te);
        ts === 0 ? setTotalSupplied("") : setTotalSupplied(ts);

        const overAlloc = parseImagesOverAllocation(tp, jobData, clientData);

        setImagesOverAllocation(overAlloc);
        firebase
          .firestore()
          .collection("JobData")
          .doc(jobData.id)
          .update({
            imagesProcessedNumbers: tp,
          })
          .catch((e) => console.log(e));
        firebase
          .firestore()
          .collection("JobData")
          .doc(jobData.id)
          .update({
            imagesOverAllocation: overAlloc,
          })
          .catch((e) => console.log(e));
      }
    } catch (e) {
      setTotalProccesed("");
      setTotalExpected("");
      setTotalSupplied("");
    }
  }, [imageBreakdown, clientData, firebase, jobData]);

  const [openPostProModal, setOpenPostProModal] = useState(false);

  const handlePostProClose = () => {
    setOpenPostProModal(false);
  };

  const regenerateBreakdownItems = () => {
    const breakdown = [];

    if (clientData.imageBreakdown) {
      clientData.imageBreakdown.forEach((bri) => {
        const newitemObj = {
          id: bri.id,
          jobTypesFilter: bri.jobTypesFilter || [],
          description: bri.description,
          dontAddToTotalSupplied: bri.dontAddToTotalSupplied || false,
          quantityProcessed: "",
          filesInPost: "",
          quantitySupplied: "",
          expectedPerHour: bri.expectedPerHour,
          costPerFile: bri.costPerFile,
          existingItem: true,
          itemHidden: false,
        };
        breakdown.push(newitemObj);
      });
    }
    breakdown.forEach((itemA) => {
      if (!imageBreakdown.some((itemB) => itemB.id === itemA.id)) {
        imageBreakdown.push(itemA);
      }
    });
    setImageBreakdown([...imageBreakdown]);
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ imageBreakdown: imageBreakdown }, { merge: true })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <SeePostProductionModal
        open={openPostProModal}
        handleClose={handlePostProClose}
      />
      <div style={titleStyle}>
        <span>
          {isVideoJob(jobData) ? "Video Breakdown" : "Image Breakdown"}
        </span>
        <div>
          <Switch
            checked={showHiddenItems}
            onChange={handleShowHiddenToggle}
            size="small"
            sx={{
              display: hasHiddenItems ? "inline-flex" : "none",
            }}
          />
          <IconButton
            sx={{ marginRight: 2 }}
            onClick={regenerateBreakdownItems}
          >
            <ReplayIcon />
          </IconButton>
          <IconButton onClick={toggleRemoveBreakdownItem} disabled={isTyping}>
            <RemoveIcon />
          </IconButton>
          <IconButton onClick={addNewBreakdownItem} disabled={isTyping}>
            <AddIcon />
          </IconButton>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: 15,
          paddingTop: 8,
          paddingLeft: 35,
          paddingRight: 35,
        }}
      >
        <>
          <TextField
            inputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            label={isVideoJob(jobData) ? "Total Shot" : "Total Processed"}
            disabled={true}
            variant="filled"
            value={totalProccesed}
            size="small"
            sx={{
              width: "32%",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor:
                  theme === "dark" ? "rgb(190, 190, 190)" : "rgb(10, 10, 10)",
              },
            }}
            InputProps={{ disableUnderline: true }}
          />
          <TextField
            inputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            label={isVideoJob(jobData) ? "Total Edited" : "Expected Supplied"}
            disabled={true}
            variant="filled"
            value={totalExpected}
            size="small"
            sx={{
              width: "32%",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor:
                  theme === "dark" ? "rgb(190, 190, 190)" : "rgb(10, 10, 10)",
              },
            }}
            InputProps={{ disableUnderline: true }}
          />
          <TextField
            inputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            label="Total Supplied"
            disabled={true}
            variant="filled"
            value={totalSupplied}
            size="small"
            sx={{
              width: "32%",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor:
                  theme === "dark" ? "rgb(190, 190, 190)" : "rgb(10, 10, 10)",
              },
              webkitTouchCallout: "none",
              webkitUserSelect: "none",
              khtmlUserSelect: "none",
              MozUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
            }}
            InputProps={{ disableUnderline: true }}
          />
        </>
      </div>
      {isVideoJob(jobData) ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            // marginTop: 4,
            gap: 10,
            padding: "10px 35px 10px 35px",
          }}
        >
          <AdvancedTextField
            firebase={firebase}
            field="videoTimeEstimate"
            collectionName="JobData"
            docId={jobData.id}
            label="Video Time Estimate"
            variant="filled"
            size="small"
            value={videoTimeEstimate}
            setValue={setVideoTimeEstimate}
            InputProps={{ disableUnderline: true }}
            inputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            setStopModalClose={setStopModalClose}
          />
          <AdvancedTextField
            firebase={firebase}
            field="videoNotes"
            collectionName="JobData"
            docId={jobData.id}
            label="Video Notes"
            variant="filled"
            size="small"
            value={videoNotes}
            setValue={setVideoNotes}
            InputProps={{ disableUnderline: true }}
            inputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            multiline
            setStopModalClose={setStopModalClose}
          />
        </div>
      ) : null}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: -10,
          gap: 2,
          padding: 25,
        }}
      >
        {imageBreakdown &&
          imageBreakdown.map((bi, index) => (
            <BreakdownItem
              key={bi.id}
              item={bi}
              items={imageBreakdown}
              setItems={setImageBreakdown}
              index={index}
              showHiddenItems={showHiddenItems}
              showRemoveBreakdownItem={showRemoveBreakdownItem}
              firebase={firebase}
              jobData={jobData}
              updateFS={updateFS}
              imageBreakdownClient={imageBreakdownClient}
            />
          ))}
      </div>

      {jobData?.jobTypeFull?.includes("-V") ||
      jobData?.jobTypeFull?.includes("-E") ? (
        <VideoEditingBreakdown
          titleStyle={titleStyle}
          videoEditingBreakdown={videoEditingBreakdown}
          setVideoEditingBreakdown={setVideoEditingBreakdown}
          firebase={firebase}
          jobData={jobData}
          staffData={staffData}
          updateFS={updateFS}
          findStaff={findStaff}
        />
      ) : null}

      <Catering
        titleStyle={titleStyle}
        catering={catering}
        setCatering={setCatering}
        firebase={firebase}
        jobData={jobData}
        updateFS={updateFS}
      />

      <BackgroundRolls
        titleStyle={titleStyle}
        backgroundRolls={backgroundRolls}
        setBackgroundRolls={setBackgroundRolls}
        firebase={firebase}
        jobData={jobData}
        updateFS={updateFS}
      />

      <Extras
        titleStyle={titleStyle}
        handleValueChange={handleValueChange}
        cStrings={cStrings}
        setCStrings={setCStrings}
        nippleCovers={nippleCovers}
        setNippleCovers={setNippleCovers}
        printedPages={printedPages}
        setPrintedPages={setPrintedPages}
        firebase={firebase}
        updateFS={updateFS}
        jobData={jobData}
      />

      <Dates
        titleStyle={titleStyle}
        bookedStart={bookedStart}
        bookedFinish={bookedFinish}
        startTime={startTime}
        setStartTime={setStartTime}
        updateFS={updateFS}
        firebase={firebase}
        jobData={jobData}
        finishTime={finishTime}
        setFinishTime={setFinishTime}
        overtime={overtime}
        setOvertime={setOvertime}
      />

      <Staff
        titleStyle={titleStyle}
        additionalOperators={additionalOperators}
        textFieldStyle={textFieldStyle}
        staffData={staffData}
        findStaff={findStaff}
        operator={operator}
        setOperator={setOperator}
        firebase={firebase}
        jobData={jobData}
        retoucherError={retoucherError}
        jobTypeFull={jobTypeFull}
        retoucher={retoucher}
        setRetoucher={setRetoucher}
        retouchingTimeError={retouchingTimeError}
        retouchingTime={retouchingTime}
        handleValueChange={handleValueChange}
        setRetouchingTime={setRetouchingTime}
      />
    </>
  );
};

export default Invoicing;

const isVideoJob = (jobData) => {
  if (jobData.jobTypeFull.includes("-V")) return true;
  if (jobData.jobTypeFull.includes("-E")) return true;
  return false;
};

const parseImagesOverAllocation = (images, jobData, clientData) => {
  let imageAllocation;
  if (jobData.dayType === "Full Day") {
    if (!isNaN(clientData?.fullDayRate?.images)) {
      imageAllocation = clientData?.fullDayRate?.images;
    } else imageAllocation = 240;
  } else if (jobData.dayType === "Half Day") {
    if (!isNaN(clientData?.halfDayRate?.images)) {
      imageAllocation = clientData?.halfDayRate?.images;
    } else imageAllocation = 120;
  } else if (jobData.creativeDayType === "Full Day") {
    if (!isNaN(clientData?.contentFullDayRate?.images)) {
      imageAllocation = clientData?.contentFullDayRate?.images;
    } else imageAllocation = 240;
  } else if (jobData.creativeDayType === "Half Day") {
    if (!isNaN(clientData?.contentHalfDayRate?.images)) {
      imageAllocation = clientData?.contentHalfDayRate?.images;
    } else imageAllocation = 120;
  } else {
    imageAllocation = 240;
  }
  if (images > parseInt(imageAllocation)) {
    return true;
  } else return false;
};

const SeePostProductionModal = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add New Breakdown Item</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please talk to Post Production about adding a new Breakdown Item.
        </DialogContentText>
        <DialogContentText sx={{ marginTop: 2 }}>
          The Breakdown Items are very important to get correct, so we need to
          ensure they arent being added incorrectly.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Okie Dokie
        </Button>
      </DialogActions>
    </Dialog>
  );
};
