import React, { useState, useEffect } from "react";

import { useAtom } from "jotai";
import { themeAtom } from "../../../-Atoms";
// import Contacts from "./Contacts";
import Contacts from "../Contacts";
// import Models from "./Models";
import ModelsContainer from "../../ModelsContainer";
import HMUContainer from "../../HMUContainer";
// import HMUs from "./HMUs";

const People = ({ jobData, clientData, firebase }) => {
  const [theme] = useAtom(themeAtom);
  const [models, setModels] = useState(jobData?.models);

  useEffect(() => {
    setModels(jobData?.models);
  }, [jobData]);

  const titleStyle = {
    marginLeft: 10,
    marginRight: 20,
    marginBottom: 0,
    marginTop: 10,
    color: theme === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "bold",
    fontSize: "1.1rem",
    lineHeight: "1.4375em",
    letterSpacing: "0.00938em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
  return (
    <div style={{ padding: 10 }}>
      <Contacts client={clientData} firebase={firebase} />
      <ModelsContainer
        jobData={jobData}
        models={models}
        setModels={setModels}
        firebase={firebase}
      />
      <HMUContainer jobData={jobData} firebase={firebase} />
    </div>
  );
};

export default People;
