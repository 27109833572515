import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

import AdditionalStaffContainer from "../AdditionalStaffContainer";
import SpecialRequirementsContainer from "./SpecialRequirementsContainer";
import PrefixSelector from "./PrefixSelector";
import Avatar from "@mui/material/Avatar";

const SpecialEventContainer = ({
  firebase,
  jobData,
  staffData,
  title,
  setTitle,
  recurringEvent,
  setRecurringEvent,
  rrule,
  setRrule,
  exDate,
  setExDate,
  currentUser,
  updateFSNow,
  bookedStart,
  setBookedStart,
  bookedFinish,
  setBookedFinish,
  canEdit,
  events,
  event,
  photographer,
  setPhotographer,
  additionalPhotographers,
  setAdditionalPhotographers,
  eventNotes,
  setEventNotes,
  isMobile,
  eventPrefix,
  eventPrefixCustom,
  setEventPrefix,
  setEventPrefixCustom,
  findStaffAvatar,
}) => {
  const employedStaff = staffData.filter((staff) => staff.currentlyEmployed);

  const addAdditionalStaff = async (additionalStaff, setAdditionalStaff) => {
    additionalStaff.push("");
    setAdditionalStaff([...additionalStaff]);
  };
  // eslint-disable-next-line
  const [shouldShowStaffSelector, setShouldShowStaffSelector] = useState(true);

  const [id, setId] = useState("");

  useEffect(() => {
    if (jobData.id) {
      setId(jobData.id);
      return;
    }
    if (event?.extendedProps?.id) {
      setId(event.extendedProps.id);
      return;
    }
  }, [jobData, event]);

  return (
    <>
      <TextField
        fullWidth
        label="Title"
        value={title || ""}
        variant="filled"
        InputProps={{ disableUnderline: true }}
        onChange={(e) => {
          if (!currentUser) return;
          setTitle(e.target.value);
          updateFSNow(firebase, id, "title", e.target.value);
        }}
        sx={{ marginTop: 2 }}
      />
      {/* {jobData.studio === "Critical Events" || jobData.studio === "Staff" ? ( */}
      {shouldShowStaffSelector ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Autocomplete
              disablePortal
              fullWidth
              sx={{ marginTop: 2 }}
              options={employedStaff}
              getOptionLabel={(option) => findStaff(option, employedStaff)}
              renderOption={(props, option) => (
                <li {...props}>
                  <span
                    style={{
                      color: checkIfStaffAssigned(option, events, event)
                        ? "#d13c36"
                        : "",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      style={{ marginRight: 10 }}
                      sx={{ width: 28, height: 28 }}
                      alt={findStaff(option, employedStaff)}
                      src={findStaffAvatar(option, employedStaff)}
                    />{" "}
                    {findStaff(option, employedStaff)}
                  </span>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Staff"
                  variant="filled"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    startAdornment: (
                      <Avatar
                        sx={{ width: 28, height: 28 }}
                        style={{ marginRight: 10 }}
                        alt={photographer}
                        src={findStaffAvatar(photographer, employedStaff)}
                      />
                    ),
                  }}
                />
              )}
              value={photographer || ""}
              onChange={(e, value) => {
                if (!canEdit) return;
                let val = "";
                if (value?.id) val = value.id;
                setPhotographer(val || "");
                firebase
                  .firestore()
                  .collection("JobData")
                  .doc(id)
                  .update({ photographer: val || "" }, { merge: true });
              }}
            />
            <IconButton
              onClick={() => {
                if (!canEdit) return;
                addAdditionalStaff(
                  additionalPhotographers,
                  setAdditionalPhotographers
                );
              }}
              style={{ marginRight: -8, marginLeft: 20, marginTop: 17 }}
            >
              <AddIcon />
            </IconButton>
          </div>
          <div style={{ display: photographer ? "block" : "none" }}>
            <AdditionalStaffContainer
              kind="photographer"
              additionalStaff={additionalPhotographers}
              setAdditionalStaff={setAdditionalPhotographers}
              staffData={employedStaff}
              firebase={firebase}
              jobData={jobData}
              checkIfStaffAssigned={checkIfStaffAssigned}
              events={events}
              event={event}
              findStaffAvatar={findStaffAvatar}
            />
          </div>
        </>
      ) : null}
      <Divider style={{ marginTop: 25, marginBottom: 25 }} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="Start Date"
            value={bookedStart || null}
            sx={{ marginRight: 0.5 }}
            format="DD/MM/YYYY hh:mm a"
            onChange={(e) => {
              try {
                if (!currentUser) return;
                setBookedStart(e.toISOString());
                firebase.firestore().collection("JobData").doc(id).update(
                  {
                    bookedStart: e.toISOString(),
                    start: e.toISOString(),
                    shootDate: e.toISOString(),
                  },
                  { merge: true }
                );
              } catch (e) {
                console.log(e);
              }
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: "filled",
                InputProps: { disableUnderline: true },
                size: "small",
              },
            }}
          />

          <DateTimePicker
            label="End Date"
            value={bookedFinish || null}
            sx={{ marginLeft: 0.5 }}
            format="DD/MM/YYYY hh:mm a"
            onChange={(e) => {
              try {
                if (!currentUser) return;
                setBookedFinish(e.toISOString());
                firebase.firestore().collection("JobData").doc(id).update(
                  {
                    bookedFinish: e.toISOString(),
                    end: e.toISOString(),
                  },
                  { merge: true }
                );
              } catch (e) {
                console.log(e);
              }
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: "filled",
                InputProps: { disableUnderline: true },
                size: "small",
              },
            }}
          />
        </LocalizationProvider>
      </div>
      <PrefixSelector
        isMobile={isMobile}
        canEdit={canEdit}
        firebase={firebase}
        jobData={jobData}
        eventPrefix={eventPrefix}
        eventPrefixCustom={eventPrefixCustom}
        setEventPrefix={setEventPrefix}
        setEventPrefixCustom={setEventPrefixCustom}
      />
      <Divider style={{ marginTop: 25 }} />
      <SpecialRequirementsContainer
        value={eventNotes}
        setValue={setEventNotes}
        field="eventNotes"
        label="Event Notes (will only show up in calendar, subject to space"
        firebase={firebase}
        updateFSNow={updateFSNow}
        jobData={jobData}
        marginTop={25}
        canEdit={canEdit}
        event={event}
      />
      <Divider style={{ marginTop: 70, marginBottom: 15 }} />
      <div>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={recurringEvent || false}
                onChange={(e) => {
                  setRecurringEvent(e.target.checked);
                  firebase
                    .firestore()
                    .collection("JobData")
                    .doc(id)
                    .update(
                      { recurringEvent: e.target.checked },
                      { merge: true }
                    );
                  firebase
                    .firestore()
                    .collection("JobData")
                    .doc(id)
                    .update(
                      {
                        rrule: {
                          byweekday: [],
                          freq: "weekly",
                          interval: 1,
                          dtstart: jobData.bookedStart || null,
                          until: new Date().setYear(
                            new Date().getFullYear() + 20
                          ),
                        },
                      },
                      { merge: true }
                    );
                }}
              />
            }
            label="Recurring Event"
          />
        </FormGroup>
        {recurringEvent ? (
          <>
            <div style={{ marginBottom: 10 }}>
              <FormControl sx={{ marginTop: 3, width: 200 }}>
                <InputLabel id="freq-selector">Frequency</InputLabel>
                <Select
                  labelId="freq-selector-select"
                  value={rrule?.freq || ""}
                  label="Frequency"
                  onChange={(e) => {
                    // if (!canEdit) return;
                    let obj = { ...rrule, freq: e.target.value };
                    setRrule(obj);
                    updateFSNow(firebase, id, "rrule", obj);
                  }}
                >
                  <MenuItem value="weekly">Weekly</MenuItem>
                  {/* <MenuItem value="monthly">Monthly</MenuItem>
            <MenuItem value="yearly">Yearly</MenuItem> */}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <span>Every</span>
              <TextField
                type="number"
                value={rrule?.interval || ""}
                onChange={(e) => {
                  // if (!canEdit) return;
                  // console.log(e.target.value);
                  // return;
                  let obj = { ...rrule, interval: e.target.value };
                  setRrule(obj);
                  updateFSNow(firebase, id, "rrule", obj);
                }}
                sx={{ width: 60, marginLeft: 1, marginRight: 1 }}
                size="small"
              />
              <span>{rrule?.freq.replace("ly", "")}(s) on:</span>

              <ToggleButtonGroup
                sx={{ marginLeft: 1 }}
                value={rrule?.byweekday || []}
                onChange={(e, value) => {
                  let obj = { ...rrule, byweekday: value, bymonth: [] };
                  setRrule(obj);
                  updateFSNow(firebase, id, "rrule", obj);
                }}
              >
                <ToggleButton value="mo">
                  <span>MON</span>
                </ToggleButton>
                <ToggleButton value="tu">
                  <span>TUE</span>
                </ToggleButton>
                <ToggleButton value="we">
                  <span>WED</span>
                </ToggleButton>
                <ToggleButton value="th">
                  <span>THU</span>
                </ToggleButton>
                <ToggleButton value="fr">
                  <span>FRI</span>
                </ToggleButton>
                <ToggleButton value="sa">
                  <span>SAT</span>
                </ToggleButton>
                <ToggleButton value="su">
                  <span>SUN</span>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <Button
              variant="outlined"
              onClick={() => {
                const year = event.start.getFullYear();
                let month = event.start.getMonth() + 1;
                if (month < 10) month = `0${month}`;
                let day = event.start.getDate();
                if (day < 10) day = `0${day}`;

                const exDateStr = `${year}-${month}-${day}`;
                exDate.push(exDateStr);
                setExDate([...exDate, exDateStr]);
                updateFSNow(firebase, id, "exdate", exDate);
              }}
            >
              Exclude Date
            </Button>
          </>
        ) : null}
      </div>
      <div style={{ marginTop: 50 }}>&zwnj;</div>
    </>
  );
};

export default SpecialEventContainer;

const checkIfStaffAssigned = (option, events, event) => {
  const date = `${event.start.getFullYear()}-${
    event.start.getMonth() + 1
  }-${event.start.getDate()}`;
  for (let i = 0; i < events.length; i++) {
    let e = events[i];
    if (!e.start) continue;
    let start = new Date(e.start);
    // console.log(e.start);
    let d = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`;
    if (d === date) {
      if (e.extendedProps.photographer === option.id) return true;
      if (e.extendedProps.operator === option.id) return true;
      if (e.extendedProps.stylist === option.id) return true;

      if (e.extendedProps.additionalPhotographers?.includes(option.id))
        return true;
      if (e.extendedProps.additionalOperators?.includes(option.id)) return true;
      if (e.extendedProps.additionalStylists?.includes(option.id)) return true;
    }
  }
  return false;
};

const findStaff = (option, staffData) => {
  if (option.firstName) return `${option.firstName} ${option.lastName}`;
  for (let i = 0; i < staffData.length; i++) {
    if (staffData[i].id === option) {
      return `${staffData[i].firstName} ${staffData[i].lastName}`;
    }
  }
  return option;
};
