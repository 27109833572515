import React, { useState, useEffect } from "react";

import {
  TextField,
  Tooltip,
  Snackbar,
  Alert,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
// import { saveAs } from "file-saver";

import { useAtom } from "jotai";
import { isAdminAtom } from "../../../-Atoms";

import FileSizes from "../FileSizes";

const fileFormats = ["psd", "jpg", "png", "webp", "tiff", "mp4", "webm"];
const colourSpaces = ["sRGB", "Adobe 1998", "CMYK"];
const fileSizeUnits = ["KB", "MB"];

const cardStyle = {
  // background: "#00000005",
  borderRadius: "10px",
  padding: "8px",
  margin: "8px",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  minWidth: "220px",
  maxWidth: "250px",
};
const textFieldStyle = {
  marginBottom: "8px",
  marginLeft: 4,
  width: 224,
};
const textFieldStyleSml = {
  margin: "5px",
  maxWidth: "45%",
};
const JobInfo = ({ t, jobData, clientData, firebase, isDarkMode }) => {
  const [lifeFileSizes, setLifeFileSizes] = useState(
    clientData?.fileSizes?.life
  );
  const [productFileSizes, setProductFileSizes] = useState(
    clientData?.fileSizes?.product
  );
  const [videoFileSizes, setVideoFileSizes] = useState(
    clientData?.fileSizes?.video
  );
  const [otherFileSize1, setOtherFileSize1] = useState(
    clientData?.fileSizes?.other1
  );
  const [otherFileSize2, setOtherFileSize2] = useState(
    clientData?.fileSizes?.other2
  );
  const [otherFileSize3, setOtherFileSize3] = useState(
    clientData?.fileSizes?.other3
  );
  const [otherFileSize4, setOtherFileSize4] = useState(
    clientData?.fileSizes?.other4
  );

  const [jobTypeFull, setJobTypeFull] = useState(jobData?.jobTypeFull);

  useEffect(() => {
    setLifeFileSizes(clientData?.fileSizes?.life);
    setProductFileSizes(clientData?.fileSizes?.product);
    setVideoFileSizes(clientData?.fileSizes?.video);
    setOtherFileSize1(clientData?.fileSizes?.other1);
    setOtherFileSize2(clientData?.fileSizes?.other2);
    setOtherFileSize3(clientData?.fileSizes?.other3);
    setOtherFileSize4(clientData?.fileSizes?.other4);
  }, [clientData]);

  useEffect(() => {
    setJobTypeFull(jobData.jobTypeFull);
  }, [jobData]);

  const [copyToClipboardSnackbarOpen, setCopyToClipboardSnackbarOpen] =
    useState(false);

  const updateFSCDB = debounce(async (id, field, value) => {
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(id)
      .set({ [field]: value }, { merge: true });
  }, 800);

  const [isAdmin] = useAtom(isAdminAtom);

  return (
    <div>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <FileSizes
          client={clientData}
          firebase={firebase}
          updateFS={updateFSCDB}
          canEdit={isAdmin}
          theme={isDarkMode ? "dark" : "light"}
          filter={
            jobTypeFull?.includes("-L") || jobTypeFull?.includes("-R")
              ? "life"
              : jobTypeFull?.includes("-P") || jobTypeFull?.includes("-R")
              ? "product"
              : jobTypeFull?.includes("-V") || jobTypeFull?.includes("-E")
              ? "video"
              : false
          }
        />
      </div>
      <Snackbar
        open={copyToClipboardSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setCopyToClipboardSnackbarOpen(false)}
      >
        <Alert elevation={6} variant="filled" severity="success">
          Settings copied to clipboard
        </Alert>
      </Snackbar>
      {/* {jobTypeFull?.includes("-L") || jobTypeFull?.includes("-R") ? (
        lifeFileSizes ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: "22px",
                padding: "4px",
                color: isDarkMode ? "rgb(192, 194, 195)" : "rgba(0, 0, 0, 0.6)",
              }}
            >
              Life
            </span>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(JSON.stringify(lifeFileSizes));
                setCopyToClipboardSnackbarOpen(true);
              }}
            >
              Copy Settings
            </Button>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {lifeFileSizes.map((fs, index) => (
                <FileSizeCategory
                  key={`FS-CAT-LIFE-${index}`}
                  fs={fs}
                  jobTypeFull={jobTypeFull}
                  firebase={firebase}
                  clientData={clientData}
                />
              ))}
            </div>
          </div>
        ) : (
          <div>No life file sizes in database</div>
        )
      ) : null}

      {jobTypeFull?.includes("-P") || jobTypeFull?.includes("-R") ? (
        productFileSizes ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: "22px",
                padding: "4px",
                color: isDarkMode ? "rgb(192, 194, 195)" : "rgba(0, 0, 0, 0.6)",
              }}
            >
              Product
            </span>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(JSON.stringify(productFileSizes));
                setCopyToClipboardSnackbarOpen(true);
              }}
            >
              Copy Settings
            </Button>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {productFileSizes.map((fs, index) => (
                <FileSizeCategory
                  key={`FS-CAT-LIFE-${index}`}
                  fs={fs}
                  jobTypeFull={jobTypeFull}
                  firebase={firebase}
                  clientData={clientData}
                />
              ))}
            </div>
          </div>
        ) : (
          <div>No product file sizes in database</div>
        )
      ) : null}

      {jobTypeFull?.includes("-V") || jobTypeFull?.includes("-E") ? (
        videoFileSizes ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "22px", padding: "4px" }}>Video</span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {videoFileSizes.map((fs, index) => (
                <FileSizeCategory
                  key={`FS-CAT-LIFE-${index}`}
                  fs={fs}
                  jobTypeFull={jobTypeFull}
                  firebase={firebase}
                  clientData={clientData}
                />
              ))}
            </div>
          </div>
        ) : (
          <div>No video file sizes in database</div>
        )
      ) : null}
      <div
        style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
      >
        {otherFileSize1 && !jobTypeFull?.includes("-V") ? (
          <div>
            <Tooltip title="Other File Size 1" enterDelay={500}>
              <TextField
                variant="outlined"
                label="Other File Size 1"
                value={otherFileSize1}
                InputLabelProps={{ style: { fontSize: "15px" } }}
                style={{ margin: "10px" }}
                size="small"
              />
            </Tooltip>
          </div>
        ) : null}
        {otherFileSize2 && !jobTypeFull?.includes("-V") ? (
          <div>
            <Tooltip title="Other File Size 2" enterDelay={500}>
              <TextField
                variant="outlined"
                label="Other File Size 2"
                value={otherFileSize2}
                InputLabelProps={{ style: { fontSize: "15px" } }}
                style={{ margin: "10px" }}
                size="small"
              />
            </Tooltip>
          </div>
        ) : null}
        {otherFileSize3 && !jobTypeFull?.includes("-V") ? (
          <div>
            <Tooltip title="Other File Size 3" enterDelay={500}>
              <TextField
                variant="outlined"
                label="Other File Size 3"
                value={otherFileSize3}
                InputLabelProps={{ style: { fontSize: "15px" } }}
                style={{ margin: "10px" }}
                size="small"
              />
            </Tooltip>
          </div>
        ) : null}
        {otherFileSize4 && !jobTypeFull?.includes("-V") ? (
          <div>
            <Tooltip title="Other File Size 4" enterDelay={500}>
              <TextField
                variant="outlined"
                label="Other File Size 4"
                value={otherFileSize4}
                InputLabelProps={{ style: { fontSize: "15px" } }}
                style={{ margin: "10px" }}
                size="small"
              />
            </Tooltip>
          </div>
        ) : null}
      </div> */}
    </div>
  );
};

export default JobInfo;

const FileSizeCategory = ({ fs, jobTypeFull, firebase, clientData }) => {
  const [isAdmin] = useAtom(isAdminAtom);

  const [title, setTitle] = useState(fs.title);
  const [width, setWidth] = useState(fs.width);
  const [height, setHeight] = useState(fs.height);
  const [dpi, setDpi] = useState(fs.dpi);
  const [fileFormat, setFileFormat] = useState(fs.fileFormat);
  const [removeITSBackground, setRemoveITSBackground] = useState(false);
  const [backgroundColour, setBackgroundColour] = useState(fs.backgroundColour);
  const [colourSpace, setColourSpace] = useState(fs.colourSpace);
  const [fileSize, setFileSize] = useState(fs.fileSize);
  const [fileSizeUnit, setFileSizeUnit] = useState(fs.fileSizeUnit);

  useEffect(() => {
    setTitle(fs.title);
    setWidth(fs.width);
    setHeight(fs.height);
    setDpi(fs.dpi);
    setFileFormat(fs.fileFormat);
    setRemoveITSBackground(fs.removeITSBackground);
    setBackgroundColour(fs.backgroundColour);
    setColourSpace(fs.colourSpace);
    setFileSize(fs.fileSize);
    setFileSizeUnit(fs.fileSizeUnit);
  }, [fs]);

  const handleTitleChange = (e) => {
    if (!isAdmin) return;
    setTitle(e.target.value);
    fs.title = e.target.value;
    updateFSOBJ(firebase, clientData.id, "fileSizes", clientData.fileSizes);
  };
  const handleWidthChange = (e) => {
    if (!isAdmin) return;
    let val = parseInt(e.target.value);
    if (!val) {
      val = "";
    }
    setWidth(val);
    fs.width = val;
    updateFSOBJ(firebase, clientData.id, "fileSizes", clientData.fileSizes);
  };
  const handleHeightChange = (e) => {
    if (!isAdmin) return;
    let val = parseInt(e.target.value);
    if (!val) {
      val = "";
    }
    setHeight(val);
    fs.height = val;
    updateFSOBJ(firebase, clientData.id, "fileSizes", clientData.fileSizes);
  };
  const handleDpiChange = (e) => {
    if (!isAdmin) return;
    let val = parseInt(e.target.value);
    if (!val) {
      val = "";
    }
    setDpi(val);
    fs.dpi = val;
    updateFSOBJ(firebase, clientData.id, "fileSizes", clientData.fileSizes);
  };
  const handleFileFormatChange = (e, newValue) => {
    if (!isAdmin) return;
    if (!newValue) {
      newValue = "";
    }
    setFileFormat(newValue);
    fs.fileFormat = newValue;
    updateFSOBJ(firebase, clientData.id, "fileSizes", clientData.fileSizes);
  };
  const handleRemoveITSBackgroundChange = (e) => {
    if (!isAdmin) return;
    setRemoveITSBackground(e.target.checked);
    fs.removeITSBackground = e.target.checked;
    updateFSOBJ(firebase, clientData.id, "fileSizes", clientData.fileSizes);
  };
  const handleBackgroundColourChange = (e) => {
    if (!isAdmin) return;
    setBackgroundColour(e.target.value);
    fs.backgroundColour = e.target.value;
    updateFSOBJ(firebase, clientData.id, "fileSizes", clientData.fileSizes);
  };
  // const handleBackgroundColourChangeFileUpload = (url) => {
  //   if (!isAdmin) return;
  //   setBackgroundColour(url);
  //   fs.backgroundColour = url;
  //   updateFSOBJ(firebase, clientData.id, "fileSizes", clientData.fileSizes);
  // };
  const handleColourSpaceChange = (e, newValue) => {
    if (!isAdmin) return;
    if (!newValue) {
      newValue = "";
    }
    setColourSpace(newValue);
    fs.colourSpace = newValue;
    updateFSOBJ(firebase, clientData.id, "fileSizes", clientData.fileSizes);
  };
  const handleFileSizeChange = (e) => {
    if (!isAdmin) return;
    let val = parseInt(e.target.value);
    if (!val) {
      val = "";
    }
    setFileSize(val);
    fs.fileSize = val;
    updateFSOBJ(firebase, clientData.id, "fileSizes", clientData.fileSizes);
  };
  const handleFileSizeUnitChange = (e, newValue) => {
    if (!isAdmin) return;
    if (!newValue) {
      newValue = "";
    }
    setFileSizeUnit(newValue);
    fs.fileSizeUnit = newValue;
    updateFSOBJ(firebase, clientData.id, "fileSizes", clientData.fileSizes);
  };
  // const handleDeleteSupplySizeClick = () => {
  //   if (!isAdmin) return;
  //   firebase
  //     .firestore()
  //     .collection("ClientDatabase")
  //     .doc(clientData.id)
  //     .update({ [`fileSizes.${kind.toLowerCase()}`]: arrayRemove(fs) });
  // };

  return (
    <div style={cardStyle}>
      <Tooltip title="Title" enterDelay={500}>
        <TextField
          variant="filled"
          InputProps={{ disableUnderline: true }}
          label="Title"
          value={title}
          inputProps={{ style: { readOnly: true, fontSize: "13px" } }}
          style={textFieldStyle}
          size="small"
          disabled={!isAdmin}
          onChange={handleTitleChange}
        />
      </Tooltip>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        <Tooltip title="Width" enterDelay={500}>
          <TextField
            variant="filled"
            InputProps={{ disableUnderline: true }}
            label="Width"
            value={width}
            InputLabelProps={{ style: { fontSize: "15px" } }}
            style={textFieldStyleSml}
            size="small"
            disabled={!isAdmin}
            onChange={handleWidthChange}
          />
        </Tooltip>
        <Tooltip title="Height" enterDelay={500}>
          <TextField
            variant="filled"
            InputProps={{ disableUnderline: true }}
            label="Height"
            value={height}
            InputLabelProps={{ style: { fontSize: "15px" } }}
            style={textFieldStyleSml}
            size="small"
            disabled={!isAdmin}
            onChange={handleHeightChange}
          />
        </Tooltip>
        {jobTypeFull.includes("-V") ? null : (
          <Tooltip title="DPI" enterDelay={500}>
            <TextField
              variant="filled"
              InputProps={{ disableUnderline: true }}
              label="DPI"
              value={dpi}
              InputLabelProps={{ style: { fontSize: "15px" } }}
              style={textFieldStyleSml}
              size="small"
              disabled={!isAdmin}
              onChange={handleDpiChange}
            />
          </Tooltip>
        )}
        <Tooltip title="File format" enterDelay={500}>
          <Autocomplete
            disabled={!isAdmin}
            value={fileFormat}
            onChange={handleFileFormatChange}
            inputValue={fileFormat}
            onInputChange={handleFileFormatChange}
            options={fileFormats}
            style={textFieldStyleSml}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                InputProps={{ ...params.InputProps, disableUnderline: true }}
                size="small"
                label="File Format"
              />
            )}
          />
        </Tooltip>
        {jobTypeFull.includes("-V") ? null : (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: 2,
                marginBottom: 2,
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!isAdmin}
                      size="small"
                      checked={removeITSBackground}
                      onChange={handleRemoveITSBackgroundChange}
                    />
                  }
                  label={
                    <span style={{ fontSize: 15 }}>Remove ITS Background</span>
                  }
                />
              </FormGroup>
            </div>
            <Tooltip title="Background colour" enterDelay={500}>
              <TextField
                variant="filled"
                InputProps={{ disableUnderline: true }}
                label="Background"
                value={backgroundColour}
                onFocus={() => {}}
                // InputLabelProps={{ style: { fontSize: "13px" } }}
                style={textFieldStyleSml}
                size="small"
                disabled={!isAdmin}
                onChange={handleBackgroundColourChange}
              />
            </Tooltip>
            <Tooltip title="Colour space" enterDelay={500}>
              <Autocomplete
                disabled={!isAdmin}
                value={colourSpace}
                onChange={handleColourSpaceChange}
                inputValue={colourSpace}
                onInputChange={handleColourSpaceChange}
                options={colourSpaces}
                style={textFieldStyleSml}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    size="small"
                    label="Colour Space"
                  />
                )}
              />
            </Tooltip>
          </>
        )}
        <Tooltip title="Compress size" enterDelay={500}>
          <TextField
            variant="filled"
            InputProps={{ disableUnderline: true }}
            label="Compress"
            value={fileSize}
            // InputLabelProps={{ style: { fontSize: "13px" } }}
            style={textFieldStyleSml}
            size="small"
            disabled={!isAdmin}
            onChange={handleFileSizeChange}
          />
        </Tooltip>
        <Tooltip title="File size unit" enterDelay={500}>
          <Autocomplete
            value={fileSizeUnit}
            disabled={!isAdmin}
            onChange={handleFileSizeUnitChange}
            inputValue={fileSizeUnit}
            onInputChange={handleFileSizeUnitChange}
            options={fileSizeUnits}
            style={textFieldStyleSml}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                size="small"
                label="File Size Unit"
              />
            )}
          />
        </Tooltip>
      </div>
    </div>
  );
};

const updateFSOBJ = debounce(async (firebase, id, field, value) => {
  firebase
    .firestore()
    .collection("ClientDatabase")
    .doc(id)
    .set({ [field]: value }, { merge: true });
}, 800);

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
