import React, { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";

import Autocomplete from "@mui/material/Autocomplete";

import "./shootInfo.css";

import OperatorChecklistModal from "./OperatorChecklistModal";

import NotesContainer from "../../../GlobalComponents/NotesContainer";

import { useAtom } from "jotai";
import { themeAtom, isAdminAtom } from "../../../-Atoms";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const fetch = require("isomorphic-fetch");
const Dropbox = require("dropbox").Dropbox;
const dbx = new Dropbox({
  fetch: fetch,
  accessToken:
    "b-TxMhg7wmcAAAAAABiOl_5JiyeefA0elglApZxmxg3Uc9n7uXMt65Um5ajpJjuC",
});

const ShootInfo = ({
  jobData,
  staffData,
  findStaff,
  clientData,
  firebase,
  isDarkMode,
  modalWidth,
  isMobile,
  openOperatorChecklistModal,
  setOpenOperatorChecklistModal,
}) => {
  const [theme] = useAtom(themeAtom);
  const [isAdmin] = useAtom(isAdminAtom);

  const handleOperatorChecklistModalClose = () => {
    setOpenOperatorChecklistModal(false);
  };

  // eslint-disable-next-line
  const [imagesProcessedNumbers, setImagesProcessedNumbers] = useState(
    jobData.imagesProcessedNumbers
  );

  useEffect(() => {
    setImagesProcessedNumbers(jobData.imagesProcessedNumbers);
  }, [jobData]);

  const [bookedStart, setBookedStart] = useState(
    jobData.bookedStart ? dayjs(jobData.bookedStart) : null
  );
  const [dueDate, setDueDate] = useState(
    jobData.dueDate ? dayjs(jobData.dueDate) : null
  );
  const [expressDueDate, setExpressDueDate] = useState(
    jobData.expressDueDate ? dayjs(jobData.expressDueDate) : null
  );

  const [photographer, setPhotographer] = useState("");
  const [additionalPhotographers, setAdditionalPhotographers] = useState([]);
  const [stylist, setStylist] = useState("");
  const [additionalStylists, setAdditionalStylists] = useState([]);
  const [operator, setOperator] = useState("");
  const [additionalOperators, setAdditionalOperators] = useState([]);
  const [imagesScheduledToBeShot, setImagesScheduledToBeShot] = useState(
    jobData.imagesScheduledToBeShot
  );
  const [jobTypeFull, setJobTypeFull] = useState(jobData.jobTypeFull);

  const [fileNaming, setFileNaming] = useState(clientData.fileNaming);
  const [shootDrivePhoto, setShootDrivePhoto] = useState(
    clientData.shootDrivePhoto
  );
  const [shootDriveVideo, setShootDriveVideo] = useState(
    clientData.shootDriveVideo
  );

  const [spreadsheetURL, setSpreadsheetURL] = useState(
    clientData.spreadsheetURL
  );

  const [supplyLink, setSupplyLink] = useState("");
  useEffect(() => {
    function main() {
      if (!clientData.suppliedLinks) return;
      clientData.suppliedLinks.forEach((s) => {
        if (s.id === jobData.id) {
          setSupplyLink(s);
        }
      });
    }
    main();
  }, [clientData, jobData]);

  useEffect(() => {
    setBookedStart(jobData.bookedStart ? dayjs(jobData.bookedStart) : null);
    setDueDate(jobData.dueDate ? dayjs(jobData.dueDate) : null);
    setExpressDueDate(
      jobData.expressDueDate ? dayjs(jobData.expressDueDate) : null
    );

    setPhotographer(jobData.photographer);
    setAdditionalPhotographers(jobData.additionalPhotographers);
    setStylist(jobData.stylist);
    setAdditionalStylists(jobData.additionalStylists);
    setOperator(jobData.operator);
    setAdditionalOperators(jobData.additionalOperators);
    setImagesScheduledToBeShot(jobData.imagesScheduledToBeShot);
    setJobTypeFull(jobData.jobTypeFull);
  }, [jobData]);
  useEffect(() => {
    setFileNaming(clientData.fileNaming);
    setShootDrivePhoto(clientData.shootDrivePhoto);
    setShootDriveVideo(clientData.shootDriveVideo);

    setSpreadsheetURL(clientData.spreadsheetURL);
  }, [clientData]);

  const buttonStyle = {
    fontSize: "12px",
  };

  const [menuAnchorElement, setMenuAnchorElement] = useState(null);

  const handleMenuClick = (event) => {
    setMenuAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorElement(null);
  };

  const [sortedNotes, setSortedNotes] = useState([]);

  useEffect(() => {
    let tmp = [];

    jobData?.notes?.forEach((note) => {
      tmp.push(note);
    });
    clientData?.notes?.forEach((note) => {
      tmp.push(note);
    });
    setSortedNotes(sortNotes(tmp));
  }, [jobData, clientData]);

  const [selectedNotesFilter, setSelectedNotesFilter] = useState([]);

  const handleFilterChange = (event, newFilter) => {
    setSelectedNotesFilter(newFilter);
  };

  const filterButtonStyles = {
    fontSize: 11,
    fontWeight: "bold",
    padding: 0.8,
    border: `1px solid ${theme === "dark" ? "#3A3A3A" : "#DCDCDC"} !important`,
    borderRadius: "4px !important",
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ButtonGroup variant="outlined">
          {spreadsheetURL ? (
            <Button
              variant="outlined"
              sx={buttonStyle}
              onClick={() => {
                window.open(spreadsheetURL, "_blank");
              }}
            >
              Shoot List
            </Button>
          ) : null}
          <Button
            variant="outlined"
            sx={buttonStyle}
            onClick={() => {
              setOpenOperatorChecklistModal(true);
            }}
          >
            Operator Checklist
          </Button>
          {/* <Button
            variant="outlined"
            sx={buttonStyle}
            onClick={() => {
              setOpenRetoucherChecklistModal(true);
            }}
          >
            Retoucher Checklist
          </Button> */}
        </ButtonGroup>
      </div>
      {jobTypeFull?.includes("-L") ? (
        <div
          style={{
            marginTop: 30,
            marginBottom: 10,
            fontSize: "1.3rem",
            fontWeight: "bold",
          }}
        >
          {clientData.isDigitalBackground
            ? "Digital Background"
            : "Organic Background"}
        </div>
      ) : null}
      {supplyLink ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: 5,
          }}
        >
          <Button
            variant="outlined"
            sx={{ width: 200, height: 34 }}
            style={{ margin: 8 }}
            size="small"
            onClick={(e) => {
              handleMenuClick(e);
            }}
          >
            Supply Link
          </Button>
        </div>
      ) : null}
      <Menu
        anchorEl={menuAnchorElement}
        keepMounted
        open={Boolean(menuAnchorElement)}
        onClose={handleMenuClose}
        disableScrollLock={true}
        style={{ marginTop: 10 }}
      >
        <MenuItem
          sx={{ width: 200 }}
          onClick={() => {
            navigator.clipboard.writeText(supplyLink.url);
            handleMenuClose();
          }}
        >
          Copy To Clipboard
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            window.open(supplyLink.url, "_blank");
            handleMenuClose();
          }}
        >
          Open Link
        </MenuItem>
      </Menu>
      <div style={{ marginBottom: 30 }}> </div>
      <ToggleButtonGroup
        value={selectedNotesFilter}
        onChange={handleFilterChange}
        sx={{
          flexWrap: "wrap",
          justifyContent: "center",
          gap: 0.8,
        }}
        // orientation="vertical"
      >
        {isAdmin ? (
          <ToggleButton
            value="Pre-Production"
            size="small"
            sx={filterButtonStyles}
          >
            Pre-Production
          </ToggleButton>
        ) : null}
        <ToggleButton value="Photographer" size="small" sx={filterButtonStyles}>
          Photographer
        </ToggleButton>
        <ToggleButton value="Videographer" size="small" sx={filterButtonStyles}>
          Videographer
        </ToggleButton>
        <ToggleButton value="Operator" size="small" sx={filterButtonStyles}>
          Operator
        </ToggleButton>
        <ToggleButton value="Stylist" size="small" sx={filterButtonStyles}>
          Stylist
        </ToggleButton>
        <ToggleButton value="Retoucher" size="small" sx={filterButtonStyles}>
          Retoucher
        </ToggleButton>
      </ToggleButtonGroup>
      <NotesContainer
        key={"NotesContainer1"}
        sortedNotes={sortedNotes}
        selectedNotesFilter={selectedNotesFilter}
        isMobile={isMobile}
        modalWidth={modalWidth}
        theme={theme}
        newNoteModalOpen={false}
        setNewNoteModalOpen={() => {}}
        setNewSelectedNotesFilter={() => {}}
        setNewNoteKind={() => {}}
        setNewNotesText={() => {}}
        setNewNotesID={() => {}}
        setNewNote={() => {}}
        jobTab={false}
        clientTab={false}
        shootTab={true}
        kind={"Job"}
        jobData={jobData}
      />
      {/* <div
        style={{
          height: 10,
          paddingTop: 30,
          marginBottom: 25,
          width: "85%",
          // background: "red",
          borderBottom: "1px solid grey",
        }}
      ></div> */}
      <NotesContainer
        key={"NotesContainer2"}
        sortedNotes={sortedNotes}
        selectedNotesFilter={selectedNotesFilter}
        isMobile={isMobile}
        modalWidth={modalWidth}
        theme={theme}
        newNoteModalOpen={false}
        setNewNoteModalOpen={() => {}}
        setNewSelectedNotesFilter={() => {}}
        setNewNoteKind={() => {}}
        setNewNotesText={() => {}}
        setNewNotesID={() => {}}
        setNewNote={() => {}}
        jobTab={false}
        clientTab={false}
        shootTab={true}
        kind={"Permanent"}
        jobData={jobData}
      />
      <div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "center",
          padding: 10,
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            // justifyContent: "space-between",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div
              style={{
                display: "flex",
                gap: 10,
                justifyContent: "space-between",
              }}
            >
              <MobileDatePicker
                label={"Shoot Date"}
                format="ddd DD/MM/YYYY"
                value={bookedStart || null}
                disabled
                onChange={(d) => {}}
                slotProps={{
                  textField: {
                    variant: "filled",
                    size: "small",
                    InputProps: { disableUnderline: true },
                    fullWidth: true,
                    error: dueDate ? false : true,
                  },
                }}
              />
              <MobileDatePicker
                label={"Due Date"}
                format="ddd DD/MM/YYYY"
                value={dueDate || null}
                onChange={(d) => {
                  setDueDate(d);
                  updateJobData(
                    firebase,
                    jobData.id,
                    "dueDate",
                    d ? d.toISOString() : null
                  );
                }}
                slotProps={{
                  textField: {
                    variant: "filled",
                    size: "small",
                    InputProps: { disableUnderline: true },
                    fullWidth: true,
                    error: dueDate ? false : true,
                  },
                }}
              />
            </div>

            <MobileDatePicker
              label={"Express Due Date"}
              format="ddd DD/MM/YYYY"
              value={expressDueDate || null}
              onChange={(d) => {
                setExpressDueDate(d);
                updateJobData(
                  firebase,
                  jobData.id,
                  "expressDueDate",
                  d ? d.toISOString() : null
                );
              }}
              slotProps={{
                textField: {
                  variant: "filled",
                  size: "small",
                  InputProps: { disableUnderline: true },
                  fullWidth: true,
                  error: dueDate ? false : true,
                },
              }}
            />
          </LocalizationProvider>

          <Autocomplete
            disablePortal
            options={staffData}
            getOptionLabel={(option) => findStaff(option, staffData)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                fullWidth
                label={
                  jobData?.jobTypeFull?.includes("-V") ||
                  jobData?.jobTypeFull?.includes("-E")
                    ? "Videographer"
                    : "Photographer"
                }
                size="small"
                sx={{ marginTop: 2 }}
              />
            )}
            value={photographer || ""}
            onChange={(e, value) => {
              setPhotographer(value.id || "");
              firebase
                .firestore()
                .collection("JobData")
                .doc(jobData.id)
                .update({ photographer: value.id || "" }, { merge: true })
                .catch((e) => console.log(e));
            }}
          />

          {additionalPhotographers &&
            additionalPhotographers.map((ao) => (
              <TextField
                key={ao}
                label={
                  jobData?.jobTypeFull?.includes("-V") ||
                  jobData?.jobTypeFull?.includes("-E")
                    ? "Additional Videographer"
                    : "Additional Photographer"
                }
                value={findStaff(ao, staffData) || ""}
                size="small"
                variant="filled"
                InputProps={{ disableUnderline: true }}
                fullWidth
              />
            ))}
          <Autocomplete
            disablePortal
            options={staffData}
            getOptionLabel={(option) => findStaff(option, staffData)}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Stylist"
                variant="filled"
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                fullWidth
              />
            )}
            value={stylist || ""}
            onChange={(e, value) => {
              setStylist(value.id || "");
              firebase
                .firestore()
                .collection("JobData")
                .doc(jobData.id)
                .update({ stylist: value.id || "" }, { merge: true })
                .catch((e) => console.log(e));
            }}
          />
          {additionalStylists &&
            additionalStylists.map((ao) => (
              <TextField
                key={ao}
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
                label="Additional Stylist"
                value={findStaff(ao, staffData) || ""}
                size="small"
              />
            ))}
          <Autocomplete
            disablePortal
            options={staffData}
            getOptionLabel={(option) => findStaff(option, staffData)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                fullWidth
                label="Operator"
                size="small"
              />
            )}
            value={operator || ""}
            onChange={(e, value) => {
              setOperator(value.id || "");
              firebase
                .firestore()
                .collection("JobData")
                .doc(jobData.id)
                .update({ operator: value.id || "" }, { merge: true })
                .catch((e) => console.log(e));
            }}
          />
          {additionalOperators &&
            additionalOperators.map((ao) => (
              <TextField
                key={ao}
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
                label="Additional Operator"
                value={findStaff(ao, staffData) || ""}
                size="small"
              />
            ))}
          <TextField
            sx={{ marginTop: 2 }}
            multiline={true}
            disabled={true}
            size="small"
            label={
              jobTypeFull?.includes("(-V)")
                ? "Videos Scheduled"
                : "Images Scheduled"
            }
            value={imagesScheduledToBeShot || ""}
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            fullWidth
          />
          <TextField
            multiline={true}
            label="Shoot Drive"
            disabled={true}
            size="small"
            value={
              jobTypeFull?.includes("-V") || jobTypeFull?.includes("-E")
                ? shootDriveVideo
                  ? "Yes"
                  : "No" || ""
                : shootDrivePhoto
                ? "Yes"
                : "No" || ""
            }
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            fullWidth
          />
          <TextField
            multiline={true}
            disabled={true}
            size="small"
            label="File Naming"
            value={fileNaming ? "Yes" : "No" || ""}
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            fullWidth
          />
        </div>
      </div>
      <OperatorChecklistModal
        open={openOperatorChecklistModal}
        close={handleOperatorChecklistModalClose}
        jobData={jobData}
        firebase={firebase}
        isDarkMode={isDarkMode}
      />
    </div>
  );
};

export default ShootInfo;

function sortNotes(notes) {
  let newNotes = [...notes];
  newNotes.sort((a, b) => {
    var keyA = new Date(a.timestamp),
      keyB = new Date(b.timestamp);
    if (keyA < keyB) return 1;
    if (keyA > keyB) return -1;
    return 0;
  });

  let tmp = [];

  newNotes.forEach((note, index) => {
    if (note.tags.includes("Pre-Production")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });
  newNotes.forEach((note, index) => {
    if (note.tags.includes("Photographer")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });
  newNotes.forEach((note, index) => {
    if (note.tags.includes("Videographer")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });
  newNotes.forEach((note, index) => {
    if (note.tags.includes("Operator")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });
  newNotes.forEach((note, index) => {
    if (note.tags.includes("Stylist")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });
  newNotes.forEach((note, index) => {
    if (note.tags.includes("Retoucher")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });

  return tmp;
}
const updateJobData = debounce((firebase, id, field, value) => {
  firebase
    .firestore()
    .collection("JobData")
    .doc(id)
    .update({ [field]: value }, { merge: true })
    .catch((e) => console.log(e));
}, 400);
function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
// eslint-disable-next-line
async function getDropboxLinkNoCreate(path) {
  const existingLinkRes = await dbx
    .sharingListSharedLinks({
      path: path,
      direct_only: true,
    })
    .catch((error) => {
      console.error(JSON.stringify(error));
      return {
        error: true,
        errorText: error.error.error_summary,
        result: { links: [] },
      };
    });
  if (!existingLinkRes) {
    return { error: true, errorText: "Could not connect to Dropbox..." };
  }

  if (existingLinkRes.error) {
    if (existingLinkRes.errorText.includes("path/not_found")) {
      return false;
    } else return false;
  }

  if (existingLinkRes.result.links.length === 0) {
    const createLinkRes = await dbx
      .sharingCreateSharedLinkWithSettings({
        path: path,
        settings: {
          requested_visibility: "public",
          audience: "public",
          access: "viewer",
        },
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
        return false;
        // return { error: true, errorText: error.error.error_summary };
      });
    return createLinkRes.result.url;
  } else {
    return existingLinkRes.result.links[0].url;
  }
}
// eslint-disable-next-line
async function getDropboxLink(path) {
  const existingLinkRes = await dbx
    .sharingListSharedLinks({
      path: path,
      direct_only: true,
    })
    .catch((error) => {
      console.error(JSON.stringify(error));
      return {
        error: true,
        errorText: error.error.error_summary,
        result: { links: [] },
      };
    });
  if (!existingLinkRes) {
    return { error: true, errorText: "Could not connect to Dropbox..." };
  }

  if (existingLinkRes.error) {
    if (existingLinkRes.errorText.includes("path/not_found")) {
      console.log("path not found");
      const createFolderRes = await dbx
        .filesCreateFolderV2({
          path: path,
          autorename: false,
        })
        .catch((error) => {
          console.error(JSON.stringify(error));
          return { error: true, errorText: error.error.error_summary };
        });
      if (createFolderRes.error) {
        return createFolderRes;
      }
    } else return existingLinkRes;
  }

  if (existingLinkRes.result.links.length === 0) {
    const createLinkRes = await dbx
      .sharingCreateSharedLinkWithSettings({
        path: path,
        settings: {
          requested_visibility: "public",
          audience: "public",
          access: "viewer",
        },
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
        return { error: true, errorText: error.error.error_summary };
      });
    return createLinkRes.result.url;
  } else {
    return existingLinkRes.result.links[0].url;
  }
}
