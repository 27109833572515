import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
import { useAtom } from "jotai";
import { themeAtom, isAdminAtom } from "../../-Atoms";

const Contacts = ({ client, firebase }) => {
  const [themeA] = useAtom(themeAtom);
  const [isAdmin] = useAtom(isAdminAtom);

  const updateFS = debounce(async (id, field, value) => {
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(id)
      .set({ [field]: value }, { merge: true });
  }, 100);

  const [contacts, setContacts] = useState(
    Array.isArray(client?.contacts)
      ? client.contacts.map((contact) => ({
          ...contact,
          id: contact.id || uuidv4(),
        }))
      : []
  );
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  useEffect(() => {
    setContacts(
      Array.isArray(client?.contacts)
        ? client.contacts.map((contact) => ({
            ...contact,
            id: contact.id || uuidv4(),
          }))
        : []
    );
  }, [client]);

  const handleAddContact = () => {
    if (!isAdmin) return;
    const newContact = {
      id: uuidv4(),
      name: "",
      title: "",
      email: "",
      phone: "",
      isSupply: false,
      isBilling: false,
    };
    const updatedContacts = [...contacts, newContact];
    setContacts(updatedContacts);
    updateFS(client.id, "contacts", updatedContacts);
  };

  const handleDeleteContacts = () => {
    if (!isAdmin || rowSelectionModel.length === 0) return;
    const updatedContacts = contacts.filter(
      (contact) => !rowSelectionModel.includes(contact.id)
    );
    setContacts(updatedContacts);
    updateFS(client.id, "contacts", updatedContacts);
    setRowSelectionModel([]);
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 1, editable: isAdmin },
    { field: "title", headerName: "Title", flex: 1, editable: isAdmin },
    { field: "email", headerName: "Email", flex: 1, editable: isAdmin },
    { field: "phone", headerName: "Phone", flex: 1, editable: isAdmin },
    {
      field: "isSupply",
      headerName: "Supply",
      type: "boolean",
      width: 100,
      editable: isAdmin,
    },
    {
      field: "isBilling",
      headerName: "Billing",
      type: "boolean",
      width: 100,
      editable: isAdmin,
    },
  ];

  return (
    <div
      style={{
        width: "100%",
        marginBottom: 10,
        padding: 10,
        // marginRight: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <h3
          style={{
            marginLeft: 10,
            marginTop: 10,
            color:
              themeA === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)",
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontWeight: "bold",
            fontSize: "1.1rem",
            lineHeight: "1.4375em",
            letterSpacing: "0.00938em",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Contacts
        </h3>
        <div style={{ display: isAdmin ? "flex" : "none" }}>
          <IconButton onClick={handleAddContact}>
            <AddIcon />
          </IconButton>
          <IconButton
            onClick={handleDeleteContacts}
            disabled={rowSelectionModel.length === 0}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
      <DataGrid
        rows={contacts}
        columns={columns}
        checkboxSelection={isAdmin}
        disableRowSelectionOnClick
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={(newSelection) => {
          setRowSelectionModel(newSelection);
        }}
        processRowUpdate={(updatedRow) => {
          const updatedContacts = contacts.map((contact) =>
            contact.id === updatedRow.id ? updatedRow : contact
          );
          setContacts(updatedContacts);
          console.log(updatedContacts);
          updateFS(client.id, "contacts", updatedContacts);
          return updatedRow;
        }}
        onProcessRowUpdateError={(error) => console.error(error)}
        getRowId={(row) => row.id}
        autoHeight
      />
    </div>
  );
};

export default Contacts;

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
