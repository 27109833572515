import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { themeAtom, isAdminAtom } from "../-Atoms";

const HMUContainer = ({ firebase, jobData }) => {
  const [themeA] = useAtom(themeAtom);
  const [isAdmin] = useAtom(isAdminAtom);
  const [hmus, setHmus] = useState([]);

  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [openPicker, setOpenPicker] = useState(null); // Track which TimePicker is open

  useEffect(() => {
    if (jobData && jobData.hmu) {
      setHmus(
        Array.isArray(jobData?.hmu)
          ? jobData.hmu.map((hmu) => ({
              ...hmu,
              id: hmu.id || generateUniqueId(hmu),
            }))
          : []
      );
    }
  }, [jobData]);

  const generateUniqueId = (hmu) => {
    return hmu.name + hmu.agency + hmu.callTime + hmu.finishTime;
  };

  const updateDatabase = async (updatedHmus) => {
    try {
      await firebase.firestore().collection("JobData").doc(jobData.id).update({
        hmu: updatedHmus,
      });
      console.log("Database updated with new hmu.");
    } catch (error) {
      console.error("Error updating database:", error);
    }
  };

  const handleAddHmu = () => {
    if (!isAdmin) return;
    const newHmu = {
      id: uuidv4(),
      name: "",
      agency: "",
      callTime: null,
      finishTime: null,
      insta: "", // Add the insta field here
    };
    const updatedHmus = [...hmus, newHmu];
    setHmus(updatedHmus);
    updateDatabase(updatedHmus);
  };

  const handleDeleteHmus = () => {
    if (!isAdmin || rowSelectionModel.length === 0) return;
    const updatedHmus = hmus.filter(
      (hmu) => !rowSelectionModel.includes(hmu.id)
    );
    setHmus(updatedHmus);
    updateDatabase(updatedHmus);
    setRowSelectionModel([]);
  };

  const handleTimeChange = (newValue, rowId, timeType) => {
    const newTime = newValue?.toISOString();
    setHmus((prevHmus) => {
      const updatedHmus = prevHmus.map((hmu) =>
        hmu.id === rowId ? { ...hmu, [timeType]: newTime } : hmu
      );
      updateDatabase(updatedHmus);
      return updatedHmus;
    });
    setOpenPicker(null); // Close the currently open picker after time change
  };

  const handleTimePickerOpen = (rowId, timeType, defaultTime) => {
    setOpenPicker(timeType); // Set the open picker state
    setHmus((prevHmus) => {
      const currentHmu = prevHmus.find((hmu) => hmu.id === rowId);
      if (!currentHmu[timeType]) {
        const updatedHmus = prevHmus.map((hmu) =>
          hmu.id === rowId
            ? { ...hmu, [timeType]: defaultTime.toISOString() }
            : hmu
        );
        updateDatabase(updatedHmus);
        return updatedHmus;
      }
      return prevHmus;
    });
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 1, editable: isAdmin },
    { field: "agency", headerName: "Agency", flex: 1, editable: isAdmin },
    { field: "insta", headerName: "Insta", flex: 1, editable: isAdmin }, // Added Insta field here
    {
      field: "callTime",
      headerName: "Call Time",
      flex: 1,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            value={params.row.callTime ? dayjs(params.row.callTime) : null}
            disabled={openPicker === "finishTime"} // Disable when the finishTime picker is open
            onOpen={() =>
              handleTimePickerOpen(
                params.row.id,
                "callTime",
                dayjs().hour(8).minute(30)
              )
            }
            onClose={() => setOpenPicker(null)} // Reset when the picker closes
            onChange={(newValue) =>
              handleTimeChange(newValue, params.row.id, "callTime")
            }
            onAccept={(newValue) =>
              handleTimeChange(newValue, params.row.id, "callTime")
            } // Triggered when OK is pressed
            slotProps={{
              textField: {
                size: "small",
                variant: "filled",
                InputProps: { disableUnderline: true },
              },
            }}
          />
        </LocalizationProvider>
      ),
      editable: isAdmin,
    },
    {
      field: "finishTime",
      headerName: "Finish Time",
      flex: 1,
      renderCell: (params) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            value={params.row.finishTime ? dayjs(params.row.finishTime) : null}
            disabled={openPicker === "callTime"} // Disable when the callTime picker is open
            onOpen={() =>
              handleTimePickerOpen(
                params.row.id,
                "finishTime",
                dayjs().hour(16).minute(30)
              )
            }
            onClose={() => setOpenPicker(null)} // Reset when the picker closes
            onChange={(newValue) =>
              handleTimeChange(newValue, params.row.id, "finishTime")
            }
            onAccept={(newValue) =>
              handleTimeChange(newValue, params.row.id, "finishTime")
            } // Triggered when OK is pressed
            slotProps={{
              textField: {
                size: "small",
                variant: "filled",
                InputProps: { disableUnderline: true },
              },
            }}
          />
        </LocalizationProvider>
      ),
      editable: isAdmin,
    },
  ];

  return (
    <div
      style={{
        width: "100%",
        marginBottom: 10,
        padding: 10,
        overflowY: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <h3
          style={{
            marginTop: 10,
            color:
              themeA === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)",
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontWeight: "bold",
            fontSize: "1.1rem",
            lineHeight: "1.4375em",
            letterSpacing: "0.00938em",
          }}
        >
          Hair & Makeup Artists
        </h3>
        <div style={{ display: isAdmin ? "flex" : "none" }}>
          <IconButton onClick={handleAddHmu}>
            <AddIcon />
          </IconButton>
          <IconButton
            onClick={handleDeleteHmus}
            disabled={rowSelectionModel.length === 0}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
      <DataGrid
        rows={hmus}
        columns={columns}
        checkboxSelection={isAdmin}
        disableRowSelectionOnClick
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={(newSelection) => {
          setRowSelectionModel(newSelection);
        }}
        processRowUpdate={(updatedRow) => {
          const updatedHmus = hmus.map((hmu) =>
            hmu.id === updatedRow.id ? updatedRow : hmu
          );
          setHmus(updatedHmus);
          updateDatabase(updatedHmus);
          return updatedRow;
        }}
        onProcessRowUpdateError={(error) => console.error(error)}
        getRowId={(row) => row.id || generateUniqueId(row)} // Ensure every row has a unique ID
        autoHeight
      />
    </div>
  );
};

export default HMUContainer;
